import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { Button, Modal, Table } from 'antd';
import { useContractsAgreement } from '../../../../actions/useContractsAgreement';
import { Load } from '../../../../shared/Load';
import { getPassportCache } from '../../../table_extends/loadData';
import { useContractsStatuses } from '../../../../actions/useContractsStatuses';

const ModalContractsAgreement: FunctionComponent<{
    isOpen: boolean,
    handleClose: () => void;
}> = (props) => {
    const { contractsAgreement, isLoadingContractsAgreement } = useContractsAgreement();
    const [selectStatus, setSelectStatus] = useState<string>('');
    const { contractsStatuses } = useContractsStatuses();

    (window as any).modalContractsAgreement = {
        props,
        contractsAgreement,
        contractsStatuses,
        userId: selectStatus,
    };

    useEffect(() => {
        const currentStatusCount = contractsStatuses.find(e => e.value == selectStatus)?.count;
        if (!currentStatusCount) {
            setSelectStatus('');
        }
    }, [contractsStatuses]);

    return <Modal
        title="Список контрактов на согласовании"
        open={props.isOpen}
        onCancel={props.handleClose}
        footer={null}
        width={1000}
        className={'contracts-agreement__modal'}
    >
        <div className={'contracts-agreement__header'}>
            <div className={'line'}>
                <span className={'line-title'}>Проект: &nbsp;</span>
                <span className={'line-value'}>{getPassportCache()?.title}</span>
            </div>
            {contractsStatuses
                .filter(e => e.count)
                .map((status) => <Button key={status.value} type={selectStatus == status.value ? 'primary' : 'link'}
                                         onClick={() => {
                                             if (selectStatus == status.value) {
                                                 setSelectStatus('');
                                                 return;
                                             }
                                             setSelectStatus(status.value ?? '');
                                         }} size={'small'}>
                    {status.text} ({status.count})
                </Button>)}

        </div>
        {isLoadingContractsAgreement && <Load wrap={true} />}
        <Table
            dataSource={(contractsAgreement?.data ?? [])
                .filter(e => {
                    if (e.status == 'signed' || e.status == 'rejected') {
                        return false;
                    }
                    if (selectStatus == '') {
                        return true;
                    }

                    return e.status == selectStatus;
                })}
            rowClassName={(record, index) => {
                const contractorDb = getWData().db.dbContractors.find(contractor => contractor.id === record.contractor_id);
                let color = '';
                if (contractorDb?.rating == 1) {
                    color = 'black';
                }
                if (contractorDb?.rating == 2) {
                    color = 'yellow';
                }
                if (contractorDb?.rating == 3) {
                    color = 'green';
                }

                return `${color}`;
            }}
            columns={[
                {
                    title: 'Подрядчик',
                    dataIndex: 'contractor_name',
                    key: 'contractor_name',
                    render: (text, record) => record.contractor_name,
                },
                {
                    width: '150px',
                    title: 'Номер контракта',
                    dataIndex: 'contract_number',
                    key: 'contract_number',
                },
                {
                    width: '150px',
                    title: 'Дата контракта',
                    dataIndex: 'contract_date',
                    key: 'contract_date',
                },
                {
                    width: '150px',
                    title: 'Сумма контракта',
                    dataIndex: 'amount',
                    key: 'amount',
                },
            ]}
            onRow={(record, rowIndex) => ({
                onClick: () => {
                    if (getWData().globalActions) {
                        console.log(record);
                        const contractLine = record.nomenclatures?.[0];
                        const contract_id = contractLine?.id;
                        getWData().globalActions.isModalErrorView = contract_id ?? '';
                        getWData().setRefresh(Math.random());
                    }
                },
            })}
        >

        </Table>

    </Modal>;
};

export default ModalContractsAgreement;

export function ModalContractsAgreementBind(props: { isOpen: boolean }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => {
        setIsModalOpen(false);
        if (getWData().globalActions) {
            getWData().globalActions.isModalContractsAgreement = false;
        }
    };

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen(props.isOpen);
        }
    }, [props.isOpen]);

    return <ModalContractsAgreement
        isOpen={isModalOpen}
        handleClose={handleClose}
    />;
}
