import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {getWData} from '../../../table_extends/getWData';
import {Button, Modal, Table, Tag} from 'antd';
import {Load} from '../../../../shared/Load';
import {ProjectError, ProjectSimpleError} from '../../../../ApiEpo';
import {dateFormatter} from '../../../../shared/dateFormat';
import { addEvent2Bus, getLoadDataInfo, LoadData, loadData, removeEventBus } from '../../../table_extends/loadData';
import {ModalErrorViewBind} from './modal_error_view';
import {getTimeClosed, getTypeError, TypeModalEnum} from "../utils/getErrorFieldPrefics";

function getDescError(error: ProjectError): string {
    return (error as any).description;

    // if (error.type == ErrorTypeEnum.CONTRACT_WORK_START_DATE_ERROR) {
    //     return `Ввод Контракта "Дата начала работ по контракту" > "Дата начала"`
    // }
    // if (error.type == ErrorTypeEnum.CONTRACT_WORK_END_DATE_ERROR) {
    //     return `Ввод Контракта "Дата завершения работ по контракту" > "Дата окончания"`
    // }
    // if (error.type == ErrorTypeEnum.CONTRACT_OVER_ERROR) {
    //     return (error as any).description
    // }
    //
    // return `Неизвестная ошибка: ${JSON.stringify(error)})`;
}

const ModalErrors: FunctionComponent<{
    isOpen: boolean,
    open: boolean,
    onOk: () => void,
    onCancel: () => void,
    passportId: string
}> = (props) => {
    const [refresh, setRefresh] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [userId, setUserId] = useState<string>('');

    const [openRow, setOpenRow] = useState<ProjectSimpleError | null>(null);

    const [passportData, setPassportData] = useState<LoadData>(getLoadDataInfo());

    const [errors, setErrors] = useState<ProjectError[]>(getLoadDataInfo()?.errors ?? []);
    const [errorsSimple, setErrorsSimple] = useState<ProjectSimpleError[]>(getLoadDataInfo()?.errorsSimple ?? []);

    function refreshData() {
        setPassportData(getLoadDataInfo());
        setErrors(getLoadDataInfo()?.errors);
        setErrorsSimple(getLoadDataInfo()?.errorsSimple);
    }

    useEffect(() => {
        addEvent2Bus('updated-errors', refreshData, 'modal_errors');

        return () => {
            removeEventBus('updated-errors', 'modal_errors');
        };
    }, []);

    useEffect(() => {
        setOpenRow(null);
        setUserId('');

        refreshData();
    }, [props.isOpen]);

    useEffect(() => {
        // console.log('ModalErrors', props.passportId);

        if (!props.passportId) {
            return;
        }

        updPassport(props.passportId);
    }, [props.passportId]);

    async function updPassport(id: string) {
        setIsLoading(true);
        try {
            await loadData(id);

            refreshData();
        } catch (e) {
            console.log(e);
            setError(e as any);
        }

        setIsLoading(false);
    }

    const users = useMemo(() => {
        const users: {
            [key: string]: {
                id?: string | undefined,
                full_name?: string | undefined
                countErrors?: number | undefined
                closedErrors?: number | undefined
            }
        } = {};
        for (const error of errors) {
            const user = error.user;
            if (user && user.id && user.full_name && !users[user.id ?? '']) {
                const countErrors = errorsSimple.filter(e => e.user_id == user.id);
                const closedErrors = countErrors.filter(e => e.closed_at != null);
                users[user.id ?? ''] = {
                    ...user,
                    countErrors: countErrors.length,
                    closedErrors: closedErrors.length
                };
            }
        }

        return Object.values(users);
    }, [errors]);

    const rowsResult = useMemo(() => {
        if (userId) {
            const errors2 = errors.filter(e => e.user?.id == userId).map(e => e.id);
            return errorsSimple.filter(e => errors2.includes(e.id));
        }
        return errorsSimple;
    }, [errorsSimple, errors, userId]);

    const errorsBySelectedRow = useMemo(() => {
        if (passportData.id) {
            const documentId = openRow?.document_id ?? '';
            const userId = openRow?.user_id ?? '';

            const contracts = passportData.rowsTable.rows3.map(e => e.cns_contracts.filter(e => e.document_id == documentId)).flat();
            const invoices = passportData.rowsTable.rows3.map(e => e.cns_invoices.filter(e => e.document_id == documentId)).flat();
            const way_bills = passportData.rowsTable.rows3.map(e => e.cns_way_bills.filter(e => e.document_id == documentId)).flat();
            const bills = passportData.rowsTable.rows3.map(e => e.cns_bills.filter(e => e.document_id == documentId)).flat();

            if (invoices.length) {
                return passportData.errors.filter(e => invoices.find(e2 => e.error?.payment_invoice?.id == e2.id && e.user?.id == userId));
            }

            if (way_bills.length) {
                return passportData.errors.filter(e => way_bills.find(e2 => e.error?.waybill?.id == e2.id && e.user?.id == userId));
            }

            if (bills.length) {
                return passportData.errors.filter(e => bills.find(e2 => e.error?.bill?.id == e2.id && e.user?.id == userId));
            }

            if (contracts.length) {
                return passportData.errors.filter(e => contracts.find(e2 => e.error?.contract?.id == e2.id && e.user?.id == userId));
            }

            return passportData.errors.filter(e => e.id == openRow?.id && e.user?.id == userId);
        }

        return [];
    }, [errors, openRow]);

    const isMobile = window.outerWidth < 768;

    const typeError: TypeModalEnum = getTypeError(openRow?.type ?? '' as any);

    (window as any).modalErrors = {
        users,
        passportData,
        errors,
        errorsSimple,
        userId,
        openRow,
        errorsBySelectedRow,
        refresh,
    };

    return <Modal title="Список ошибок"
                  open={props.open}
                  onCancel={
                      () => {
                          props.onCancel();
                      }}
                  onOk={props.onOk}
                  destroyOnClose={true}
                  className="modal-errors"
    >
        {isLoading && <Load/>}
        {error && <div>{error}</div>}
        {passportData && <div>
            <div>
                <span
                    className="modal-errors__title">Проект: <span>{passportData.passport.title}</span></span> &nbsp;&nbsp;
                <span className="modal-errors__title">Штраф: <span>Формула</span></span>
            </div>

            <br/>
            {users.map(user => <Button key={user.id} type={userId == user.id ? 'primary' : 'link'}
                                       onClick={() => {
                                           if (userId == user.id) {
                                               setUserId('');
                                               return;
                                           }
                                           setUserId(user.id ?? '');
                                       }} size={'small'}>
                {user.full_name} ( {user.closedErrors} / {user.countErrors})
            </Button>)}
            <br/>
            <br/>

            {!isLoading && !isMobile && <Table
                columns={[
                    {
                        title: 'Код ошибки',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: 'Описание',
                        dataIndex: 'message',
                        key: 'message',
                        render: (text, row) => getDescError(row),
                    },
                    {
                        title: 'Время закрытия, ч',
                        dataIndex: 'comment',
                        key: 'comment',
                        render: (text, row) => {
                            if ((row as any).status == 'open' || (row as any).status == 'late') {
                                return <Tag>В работе</Tag>;
                            }
                            return dateFormatter(row.closed_at ?? '');
                        },
                    },
                    {
                        title: 'Кто закрыл',
                        dataIndex: 'comment',
                        key: 'comment',
                        render: (text, row) => {
                            if ((row as any).status == 'open' || (row as any).status == 'late') {
                                return <Tag>В работе</Tag>;
                            }
                            return `${row.closed_by_user_name}` + (row.closed_by_position ? `(${row.closed_by_position})` : '');
                        },
                    },
                    {
                        title: 'Дата ошибки',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        render: (text, row) => dateFormatter(text),
                    },
                ]}
                rowKey={'id'}
                dataSource={rowsResult}
                onRow={(row) => ({
                    onClick: () => {
                        setOpenRow(row);
                    },
                })}
            />}

            {!isLoading && isMobile && <div>
                {rowsResult.map(e => {
                    return <div key={`error-${e.id}`} onClick={() => setOpenRow(e)}>
                        <span className={'modal-errors__item_code'}>{e.code}</span>
                        <span className={'modal-errors__item_status'}>
                        {!!e.closed_at && getTimeClosed(e.created_at!, e.closed_at)}
                            {!e.closed_at && <Tag>В работе</Tag>}
                            </span>
                        <div className={'modal-errors__item_desc'}>{getDescError(e)}</div>
                        <div className={'modal-errors__item_date'}>{dateFormatter(e.created_at!)}</div>
                    </div>;
                })}
            </div>}

            <ModalErrorViewBind
                isAgreementContract={false}
                isOpen={!!openRow}
                                onClose={() => setOpenRow(null)}
                                errors={errorsBySelectedRow}
                                onAction={(type) => {
                                    setRefresh(Math.random());
                                    if (type == 'cancel') {
                                        setOpenRow(null);
                                    }
                                }}
                                typeError={typeError}
            />
        </div>}
    </Modal>;
};

export default ModalErrors;

export function ModalErrorsBind(props: { isOpen: boolean, passportId: string, onClose: () => void }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => {
        setIsModalOpen(false);
        if (getWData().globalActions) {
            getWData().globalActions.isModalErrors = false;
        }
        props.onClose();
    };

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen(true);
        }
    }, [props.isOpen]);


    return <ModalErrors isOpen={isModalOpen}
                        open={isModalOpen}
                        onOk={handleClose}
                        onCancel={handleClose}
                        passportId={props.passportId}
    />;

}
