import { ColumnsType } from 'antd/es/table';
import { TableRow } from '../../ApiDirectory';
import { AutoComplete, Input, Popover } from 'antd';
import { stopEvent } from '../../shared/stopEvent';
import { doDownInput, doLeftInput, doRightInput, doUpInput, LocalValueNomenclature } from './NomenclatureListHelpers';
import { NomenclaturePriceLite, NomenclaturePriceLiteRegion } from "../../actions/usePricesLiteTable";
import Icon from "antd/es/icon";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import tokenActions from '../../actions/tokenActions';
import { FixedType } from 'rc-table/lib/interface';

const nomenclaturesColumns: ( props: {
    regions: NomenclaturePriceLiteRegion[],
    regionPinned: string[],
    units: string[],
    prices: NomenclaturePriceLite[],
    localValues: { [nomenclature_id: string]: LocalValueNomenclature };
    onView: ( id: string ) => void;
    onEdit: ( type: 'price' | 'unit' | 'title', section_id?: string, type_id?: string, nomenclature_id?: string, region_id?: string, value?: string ) => void;
    onEditEnd: ( type: 'price' | 'unit' | 'title', section_id?: string, type_id?: string, nomenclature_id?: string, region_id?: string, value?: string ) => void;
    onAdd: ( section_id?: string, type_id?: string ) => void;
    onAddNomenclature: () => void;
    onArchive: ( section_id?: string, type_id?: string, nomenclature_id?: string ) => void;
    onRestore: ( section_id?: string, type_id?: string, nomenclature_id?: string ) => void;
    onMove: ( direction: 'up' | 'down', item: TableRow ) => void,
    onRegionPinned: (region_id: string) => void,
    expandedRowKeys: string[],
    setExpandedRowKeys: ( keys: string[] ) => void;
    t: ( key: string ) => string;
    processSave: { id: string, region_id?: string, type: 'price' | 'unit' | 'title' }[]
    isOpenPopover: { id: string },
    setIsOpenPopover: ( props: { id: string } ) => void,
    isOpenRename: { id: string },
    setIsOpenRename: ( props: { id: string } ) => void
    setIsOpenMove: ( props: { section_id: string, type_id: string, nomenclature_id: string } ) => void
    items: TableRow[],
} ) => ColumnsType<TableRow & { hasChildren: boolean }> = ( props ) => {
    return [
        {
            width: tokenActions.directoryNomenclatures == 'EDIT' ? 90 : 70,
            fixed: 'left',
            render: ( text, record ) => {
                let items = props.items;

                if ( record.row_type == 'section' ) {
                    items = props.items.filter( item => item.row_type == 'section' )
                }

                if ( record.row_type == 'work_type' ) {
                    items = props.items.filter( item => item.section_id == record.section_id && item.row_type == 'work_type' )
                }

                if ( record.row_type == 'nomenclature' ) {
                    items = props.items.filter( item => `${item.section_id}_${item.type_id}` == `${record.section_id}_${record.type_id}` && item.row_type == 'nomenclature' )
                }

                const index = items.findIndex(e => `${e.section_id}_${e.type_id}_${e.nomenclature_id}` == `${record.section_id}_${record.type_id}_${record.nomenclature_id}`);

                const canMoveUp = index > 0
                const canMoveDown = (index + 1) < items.length

                return <div className={ `nomenclatures-table__row_number ${ record.row_type }` }>
                    { record.row_number }

                    { tokenActions.directoryNomenclatures == 'EDIT' && <>
                        <span>
                            <ArrowUpOutlined className={`${canMoveUp ? '' : 'disabled'}`}
                                             onClick={(e) => {
                                                 props.onMove('up', record);
                                                 stopEvent(e);
                                             }} />
                            &nbsp;
                            <ArrowDownOutlined className={`${canMoveDown ? '' : 'disabled'}`}
                                               onClick={(e) => {
                                                   props.onMove('down', record);
                                                   stopEvent(e);
                                               }} />
                        </span>
                    </>}
                </div>;
            },
        },
        {
            width: 500,
            fixed: 'left',
            title: <div className={'nomenclatures-table__title'}>
                <span>Наименование</span>
            </div>,
            dataIndex: 'title',
            // fixed: 'left',
            render: (text, record, index) => {
                const id: string = record.nomenclature_id || record.type_id || record.section_id as string;
                let fullId = ``;

                if (record.row_type == 'section') {
                    fullId = record.section_id!;
                } else if (record.row_type == 'work_type') {
                    fullId = `${record.section_id!}_${record.type_id!}`;
                } else {
                    fullId = `${record.section_id!}_${record.type_id!}_${record.nomenclature_id!}`;
                }

                const isExpanded = props.expandedRowKeys.includes(fullId);

                if (props.isOpenRename.id == id) {
                    const value = props.localValues[record.nomenclature_id ?? record.type_id ?? record.section_id ?? '']?.title ?? '';
                    return <Input
                        onClick={ stopEvent }
                        autoFocus
                        className={ `nomenclatures-table__title ${ record.row_type }` }
                        readOnly={ props.processSave.find( p => p.id == record.nomenclature_id && p.type == 'title' ) != undefined }
                        value={ value }
                        onChange={ val => {
                            props.onEdit( 'title', record.section_id, record.type_id, record.nomenclature_id, undefined, val.target.value );
                        } }
                        onBlur={ val => {
                            props.onEditEnd( 'title', record.section_id, record.type_id, record.nomenclature_id, undefined, val.target.value );
                            props.setIsOpenRename( { id: '' } );
                        } }
                        onKeyUp={ val => {
                            if ( val.code == 'Enter' ) {
                                props.onEditEnd( 'title', record.section_id, record.type_id, record.nomenclature_id, undefined, value );
                                props.setIsOpenRename( { id: '' } );
                            }
                            if ( val.code == 'Escape' ) {
                                props.onEditEnd( 'title', record.section_id, record.type_id, record.nomenclature_id, undefined, value );
                                props.setIsOpenRename( { id: '' } );
                            }

                            if (val.code == 'ArrowRight') {
                                doRightInput(val as any)
                            }
                            if (val.code == 'ArrowLeft') {
                                doLeftInput(val as any)
                            }
                            if (val.code == 'ArrowDown') {
                                doDownInput(val as any)
                            }
                            if (val.code == 'ArrowUp') {
                                doUpInput(val as any)
                            }
                        } }
                        data-row-index={ index }
                        data-column-index={ 0 }
                    />;
                }

                return <div className={`nomenclatures-table__title ${record.row_type}`}>
                    {record.row_type == 'section' && record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove.svg'} alt="минус" />) : null}
                    {record.row_type == 'section' && !record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus_disabled.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove_disabled.svg'}
                             alt="минус" />) : null}

                    {record.row_type == 'work_type' && record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove.svg'} alt="минус" />) : null}
                    {record.row_type == 'work_type' && !record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus_disabled.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove_disabled.svg'}
                             alt="минус" />) : null}

                    <span>
                        &nbsp;{record.title}
                        { record.row_type == 'nomenclature' && props.items.filter(e => e.nomenclature_id == record.nomenclature_id).length > 1 && <img src={`/canvas_table/icon_share.svg`} alt={'Продублирована'} className={'icon-share'} />}
                    </span>
                    {tokenActions.directoryNomenclatures == 'EDIT' &&
                        <div className={'nomenclatures-table__title_dots'} onClick={e => stopEvent(e)}>
                            <Popover
                                overlayClassName={'nomenclatures-table__popover'}
                                trigger={'click'}
                                arrow={false}
                                placement={'rightTop'}
                                open={props.isOpenPopover.id == fullId}
                                onOpenChange={val => {
                                    if (val && props.isOpenPopover.id == fullId) {
                                        props.setIsOpenPopover({ id: '' });
                                    } else {
                                        props.setIsOpenPopover({ id: fullId });
                                    }
                                }}
                                content={<div className={'ModalAddRow'}>
                                    {record.row_type == 'section' && <span onClick={() => {
                                        props.setIsOpenPopover({ id: '' });
                                        props.onAdd(record.section_id);
                                    }}>Добавить подраздел</span>}
                                    {record.row_type == 'work_type' && <span onClick={() => {
                                        props.setIsOpenPopover({ id: '' });
                                        props.onAdd(record.section_id, record.type_id);
                                    }}>Добавить номенклатуру</span>}
                                    <span onClick={e => {
                                        props.setIsOpenPopover({ id: '' });
                                        props.setIsOpenRename({ id });
                                        stopEvent(e);
                                    }}>Переименовать</span>
                                    {record.row_type == 'nomenclature' && <span onClick={e => {
                                        props.setIsOpenPopover({ id: '' });
                                        props.setIsOpenMove({
                                            nomenclature_id: record.nomenclature_id ?? '',
                                            section_id: record.section_id ?? '',
                                            type_id: record.type_id ?? '',
                                        });
                                        stopEvent(e);
                                    }}>Продублировать</span>}
                                    <span onClick={e => {
                                        props.onArchive(record.section_id, record.type_id, record.nomenclature_id);
                                    }}>Удалить</span>
                                </div>}>
                                <img src="/canvas_table/dots_1.svg" alt="" />
                            </Popover>
                        </div>}
                    {tokenActions.directoryNomenclatures != 'EDIT' && <div style={{height: '30px'}} />}
                </div>;
            },
        },
        {
            fixed: 'left',
            width: 100,
            title: 'Ед. изм.',
            dataIndex: 'unit',
            render: ( text, record, index ) => {
                if ( record.row_type == 'nomenclature' && (tokenActions.directoryMeasureUnits == 'EDIT' || tokenActions.directoryMeasureUnits == 'FULL')) {
                    const value = props.localValues[record.nomenclature_id ?? '']?.unit ?? '';

                    return <AutoComplete
                        disabled={ props.processSave.find( p => p.id == record.nomenclature_id && p.type == 'unit' ) != undefined }
                        placeholder={'Выберите единицу измерения'}
                        options={props.units.map(item => ({ value: item }))}
                        filterOption={(inputValue, option) => option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                        onSearch={value => {
                            props.onEdit( 'unit', record.section_id, record.type_id, record.nomenclature_id, undefined, value );
                        }}
                        onSelect={value => {
                            props.onEditEnd( 'unit', record.section_id, record.type_id, record.nomenclature_id, undefined, value );
                        }}
                        value={value}
                        onInputKeyDown={val => {
                            if (val.code == 'ArrowRight') {
                                doRightInput(val as any)
                            }
                            if (val.code == 'ArrowLeft') {
                                doLeftInput(val as any)
                            }
                            if (val.code == 'ArrowDown') {
                                doDownInput(val as any)
                            }
                            if (val.code == 'ArrowUp') {
                                doUpInput(val as any)
                            }
                        }}
                        data-row-index={ index }
                        data-column-index={ 1 }
                    />
                }
                if ( record.row_type == 'nomenclature' && tokenActions.directoryMeasureUnits == 'VIEW') {
                    const value = props.localValues[record.nomenclature_id ?? '']?.unit ?? '';

                    return <span className={ `${ record.row_type }` }>{ value }</span>;
                }

                return <span className={ `${ record.row_type }` }></span>;
            },
        },
        ...props.regions.map( region => {
            const isPinned = props.regionPinned.includes(region.id ?? '');
            return ({
                fixed: isPinned ? 'left' as FixedType : undefined,
                title: <div
                    className={'nomenclatures-table__title region'}
                    onClick={() => props.onRegionPinned(region.id ?? '')}
                >
                    {region.title}
                    <img src={isPinned ? `/canvas_table/icon_pin.svg` : `/canvas_table/icon_pin_2.svg`}
                         alt={'Закрепить'}
                         className={'icon'}
                    />
                </div>,
                width: 120,
                render: (text: string, record: TableRow, index: number) => {
                    const value = props.localValues[record.nomenclature_id ?? '']?.prices?.find(e => e.region_id == region.id)?.price;

                    if (record.row_type == 'nomenclature') {
                        return <div className={`${record.row_type}`}>
                            <Input
                                readOnly={props.processSave.find(p => p.id == record.nomenclature_id && p.region_id == region.id && p.type == 'price') != undefined}
                                className={record.row_type}
                                value={value}
                                onChange={(e) => {
                                    props.onEdit('price', record.section_id, record.type_id, record.nomenclature_id, region.id, e.target.value);
                                }}
                                onBlur={e => {
                                    props.onEditEnd('price', record.section_id, record.type_id, record.nomenclature_id, region.id, e.target.value);
                                }}
                                onKeyDown={val => {
                                    if (val.key == 'Enter') {
                                        props.onEditEnd('price', record.section_id, record.type_id, record.nomenclature_id, region.id, `${value}`);
                                    }

                                    if (val.code == 'ArrowRight') {
                                        doRightInput(val as any);
                                    }
                                    if (val.code == 'ArrowLeft') {
                                        doLeftInput(val as any);
                                    }
                                    if (val.code == 'ArrowDown') {
                                        doDownInput(val as any);
                                    }
                                    if (val.code == 'ArrowUp') {
                                        doUpInput(val as any);
                                    }
                                }}
                                data-row-index={index}
                                data-column-index={props.regions.indexOf(region) + 2}
                            />
                        </div>;
                    }

                    return <div className={`${record.row_type}`}></div>;
                },
            });
        }),
        {
            title: '',
            render: ( text, record ) => {
                return (
                    <div className={ `${ record.row_type }` }></div>
                );
            },
        },
    ];
};

export default nomenclaturesColumns;

