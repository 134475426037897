import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getEmptyTableRecord, TableRecord, TableRecordContract } from '../TableRecord';
import { Button, Collapse, CollapseProps, Modal, notification } from 'antd';
import { getWData } from '../../../table_extends/getWData';
import dateFormat, { dateFormatter, numberFormatter } from '../../../../shared/dateFormat';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { stopEvent } from '../../../../shared/stopEvent';
import DownloadFile from '../../../../shared/DownloadFile';
import { api, hostEpo } from '../../../../shared/api_client';
import { addEvent2Bus, getLoadDataInfo, getPassportCache, removeEventBus } from '../../../table_extends/loadData';
import tokenActions, { AccessItem } from '../../../../actions/tokenActions';
import { DocumentType, updateDocumentDb } from '../../../../indexedb';
import { ProjectError } from '../../../../ApiEpo';
import ModalErrorConfirm from './modal_error_confirm';
import {
    getNamePropsWithData,
    getTitleEntity, getTypeModalEnum,
    TypeModalEnum,
} from '../utils/getErrorFieldPrefics';
import { getErrorsByContracts } from './modal_view_contract_shared/modal_view_contract_shared';
import { delay } from '../../../../shared/delay';

const ModalViewContract: FunctionComponent<{
    record: TableRecord,
    isOpen: boolean | string,
    onClose: () => void,
    onRemoveItem: (itemId: string) => void,
    onEditItem: (itemId: string) => void,
    allowEdit: boolean,
    allowRemove: boolean,
    type: TypeModalEnum,
    errors: ProjectError[],
}> = (props) => {
    const [refresh, setRefresh] = useState(0);
    // console.log('ModalViewContract')
    const contract = typeof props.isOpen == 'string' ? props.record.cns_contracts.find(e => e.document_id == props.isOpen) : undefined;
    const nomenclature = contract ? getWData().rows3.find(e => e.cns_nomenclature_id == props.record.cns_nomenclature_id) ?? props.record : props.record;
    const allContracts = [...contract ? [contract] : nomenclature.cns_contracts ?? []];
    const allContractors = [...nomenclature.cns_contractors ?? []];

    const title = contract ? `${getPassportCache().title} (по контракту №${contract.doc_number} от ${dateFormat(contract.doc_date)})` : getPassportCache().title;
    const typeDoc = getTitleEntity(props.type, false);
    const typeDocFor = getTitleEntity(props.type, true);
    const typeDocEntity = getTypeModalEnum(props.type);
    const namePropsWithData = getNamePropsWithData(props.type);

    const itemsOtherDocs: CollapseProps['items'] = allContracts
        .filter(contract => nomenclature[namePropsWithData].filter(document => document.parent_contract_id == contract.document_id).length)
        .map(contract => {
            const contragent = allContractors.find(e => e.value == contract.contragent_id);

            const items = nomenclature[namePropsWithData].filter(document => document.parent_contract_id == contract.document_id);
            items.sort((a1, a2) => {
                if (a1.doc_date < a2.doc_date) return 1;
                if (a1.doc_date > a2.doc_date) return -1;
                return 0;
            });

            return ({
                label: <div id={`contracts_wrap_${contract.id}`} className={'modal_view_contracts__record'}>
                    <div className={'modal_view_contracts__record_title2 bold'}>
                    <span>
                        <div>
                            <span className={'title_contract1'} style={{textDecoration: contract.error_action ? 'line-through' : ''}}>Контракт</span> &nbsp;
                            <span>№ {contract.doc_number}</span> от {dateFormat(contract.doc_date)}
                        </div>
                            {contragent && props.type == TypeModalEnum.invoices && <div>
                                Поставщик: {contragent.label}
                            </div>}
                            {contract.date_start && props.type != TypeModalEnum.invoices && <div>
                                {dateFormatter(contract.date_start)} - {dateFormatter(contract.date_end)}
                            </div>}
                    </span>
                    <span>
                        <span>{numberFormatter(contract.size, {isTwoDigit: true})} {nomenclature.cns_ed_izm} × {numberFormatter(contract.price, {isTwoDigit: true})} ₽</span> = {numberFormatter(contract.sum_wat, {isTwoDigit: true})} ₽
                    </span>
                    </div>
                </div>,
                children: <>
                    {items.map(document => {
                            const {
                                errorsByContract,
                                errorsByContractClosed,
                                isErrorWithDates,
                                isErrorSize,
                                hasStatusWrapClass,
                            } = getErrorsByContracts({
                                errors: props.errors,
                                contracts: [contract],
                                item: document,
                                type: props.type,
                            });

                            return <div className={'modal_view_contracts__invoice_body'}>
                                {/*<div className={'modal_view_contracts__record_content'}>{contracts.map(item2 => {*/}

                                {/*    let errors = errorsByContract.filter(e => e.nomenclature_id == item2.line.cns_nomenclature_id);*/}
                                {/*    if (!errors.length) {*/}
                                {/*        errors = errorsByContractClosed.filter(e => e.nomenclature_id == item2.line.cns_nomenclature_id);*/}
                                {/*    }*/}
                                {/*    const hasErrorSize = errors.filter(e => e.error?.fields?.includes(`${preficsErrorField}volume`)).length;*/}
                                {/*    const hasErrorPrice = errors.filter(e => e.error?.fields?.includes(`${preficsErrorField}price`)).length;*/}
                                {/*    const hasErrorSum = errors.filter(e => e.error?.fields?.includes(`${preficsErrorField}amount`)).length;*/}
                                {/*    const classNameError = !!errors[0]?.closed_at ? 'has_warning' : 'has_error';*/}
                                {/*    // on_approval_by_spm*/}
                                {/*    return <>*/}
                                {/*        <div className={hasErrorSize ? classNameError : ''}>{item2.line.cns_title}</div>*/}
                                {/*        <div><span*/}
                                {/*            className={hasErrorSize ? classNameError : ''}>{item2.size}</span> {item2.line.cns_ed_izm} × <span*/}
                                {/*            className={hasErrorPrice ? classNameError : ''}>{item2.price}</span> ₽*/}
                                {/*        </div>*/}
                                {/*        <div> = <span className={hasErrorSum ? classNameError : ''}>{item2.sum_wat}</span> ₽</div>*/}
                                {/*    </>;*/}
                                {/*})}*/}
                                {/*</div>*/}
                                <div className={'modal_view_contracts__record_title'}>
                                    <div className={'title_contract'} onClick={async () => {
                                        getWData().globalActions.isModalErrorView = `${props.type}_${document.id}`;
                                        await delay(100)
                                        getWData().setRefresh(Math.random());
                                        await delay(200)
                                        getWData().setRefresh(Math.random());
                                    }}>
                                        {typeDoc} №{document.doc_number} от {dateFormat(document.doc_date)}
                                    </div>
                                    <div>
                                        {numberFormatter(document.size)} {nomenclature.cns_ed_izm} x {numberFormatter(document.price)} ₽
                                        = {numberFormatter(document.sum_wat)} ₽
                                        {props.allowRemove && props.type != 'contracts' && <> &nbsp; <DeleteOutlined
                                            className={'modal_view_contracts__record_delete'}
                                            onClick={(e) => {
                                                props.onRemoveItem(document.id);
                                                stopEvent(e);
                                            }}/> </>}
                                    </div>
                                </div>
                                {/*<Collapse*/}
                                {/*    defaultActiveKey={countContragentIds.length == 1 ? [`contragent_${countContragentIds[0]}`] : undefined}*/}
                                {/*    ghost items={props.type == TypeModalEnum.contracts ? itemsContracts : itemsOtherDocs}*/}
                                {/*    key={`collapse_contragents_${props.record.cns_id}`} />*/}

                                <div className={'modal_view_contracts__record_files'}>
                                    <div><DownloadFile
                                        file={`${hostEpo}/api/passport/${typeDocEntity}/${document.id}/files-archive`}
                                        title={'Скачать документы'}
                                        name={`Документы для ${typeDocFor} ${document.doc_number} от ${dateFormat(document.doc_date)}.zip`}
                                        prefics={<><DownloadOutlined/></>}/>
                                    </div>
                                </div>

                                {(isErrorSize || isErrorWithDates) &&
                                    <ModalErrorConfirm error={(isErrorSize ?? isErrorWithDates)!}
                                                       hasErrorSizeWithDates={!!isErrorSize && !!isErrorWithDates}
                                                       onAction={(type) => {
                                                       }}/>
                                }
                            </div>
                        })}
                </>,
            });
        })

    const itemsContracts = (allContracts ?? []).map(contract => {
        const contragent = allContractors.find(c => c.value === contract.contragent_id);
        const hasErrors = getWData().errors.find(error => !error.closed_at && error.error?.contract?.id === contract.id && error.type?.includes('contract'));
        const hasAgreement = getWData().db.contractsAgreement.find(item => item.id === contract.document_id && item.status != 'signed');

        return <div id={`contracts_wrap_${contract.id}`} className={'modal_view_contracts__record'}>
            <div className={'modal_view_contracts__record_title'} onClick={async () => {
                getWData().globalActions.isModalErrorView = contract.id;
                await delay(100)
                getWData().setRefresh(Math.random());
                await delay(200)
                getWData().setRefresh(Math.random());
            }}>
                            <span className={'title_contract'}
                                  style={{
                                      textDecoration: contract.error_action ? 'line-through' : '',
                                      // color: contract.status == 'signed' ? 'black' : 'grey'
                            }}>
                                Контракт № {contract.doc_number} от {dateFormat(contract.doc_date)}
                            </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <>
                    {numberFormatter(contract.size)} {nomenclature.cns_ed_izm} x {numberFormatter(contract.price)} ₽
                    = {numberFormatter(contract.sum_wat)} ₽
                </>
            </div>
            <div className={'modal_view_contracts__record_title2'}>
                <span>
                    {`Поставщик: ${contragent?.label}`}
                    {hasErrors && <img src={`/canvas_table/icon_warning.svg`} alt={`warning`} />}
                    {hasAgreement && !hasErrors && <img src={`/canvas_table/icon_contracts_agreement.svg`} alt={`agreement`} />}
                </span>
                <span>
                                {props.allowEdit && contract.status != 'signed' && <>
                                    <EditOutlined
                                        className={'modal_view_contracts__record_edit'}
                                        onClick={(e) => {
                                            props.onEditItem(contract.id);
                                            stopEvent(e);
                                        }} />
                                    &nbsp;
                                    &nbsp;
                                </>}
                    {props.allowRemove && <>
                        <DeleteOutlined
                            className={'modal_view_contracts__record_delete'}
                            onClick={(e) => {
                                props.onRemoveItem(contract.id);
                                stopEvent(e);
                            }} /></>}
                            </span>
            </div>
        </div>;

    })

    function updatedSync() {
        //    console.log('updatedSync', {...props});
        setRefresh(Math.random());
    }

    (window as any)[`modalViewContract_${props.type}`] = {
        itemsContracts,
        itemsOtherDocs,
        updatedSync,
        allContracts,
        nomenclature,
        props,
    };

    useEffect(() => {
        addEvent2Bus('updated-rows', updatedSync, 'modal-view-contract');

        return () => {
            removeEventBus('updated-rows', 'modal-view-contract')
        };
    }, [props.isOpen]);

    const countContragentIds = allContracts.map(e => e.contragent_id).reduce((uniqContragentIds, contragentId) => uniqContragentIds.includes(contragentId) ? uniqContragentIds : [...uniqContragentIds, contragentId], ['']).filter(e => e);

    return <Modal title={title} open={!!props.isOpen} footer={null} closable={true} onClose={props.onClose}
                  onCancel={props.onClose} rootClassName={'modal_view_contracts'}
                  width={600}
    >
        {/*{JSON.stringify(props.errors)}*/}
        <div className={'modal_view_contracts__title'}>{nomenclature.cns_title}</div>
        <div className={'modal_view_contracts__delimiter'}></div>
        {props.type == TypeModalEnum.contracts && <>
            <div className={'modal_view_contracts__info'}>
                <span><span className={'modal_view_contracts__info_title'}>Бюджет план:</span>
                    <span className={'modal_view_contracts__info_size'}>
                        {numberFormatter(nomenclature.cns_budget_plan_size)} {nomenclature.cns_ed_izm} × {numberFormatter(nomenclature.cns_budget_plan_price)} ₽ = {numberFormatter(nomenclature.cns_budget_plan_sum_wat)} ₽
                    </span>
                </span>
                <div className={'modal_view_contracts__info_right'}>
                    <span>{numberFormatter(nomenclature.cns_budget_plan_far)} $ / sf (м²)</span> &nbsp; &nbsp; &nbsp;
                    <span>{numberFormatter(nomenclature.cns_budget_plan_far2)} %</span>
                </div>
            </div>
            <div className={'modal_view_contracts__info last'}>
                <span><span className={'modal_view_contracts__info_title'}>По контрактам:</span>
                    <span className={'modal_view_contracts__info_size'}>
                        {numberFormatter(nomenclature.cns_budget_fakt_size)} {nomenclature.cns_ed_izm} × {numberFormatter(nomenclature.cns_budget_fakt_price)} ₽ = {numberFormatter(nomenclature.cns_budget_fakt_sum_wat)} ₽
                    </span>
                </span>
            </div>
        </>}

        {props.type != TypeModalEnum.contracts && <Collapse
            defaultActiveKey={countContragentIds.length == 1 ? [`contragent_${countContragentIds[0]}`] : undefined}
            ghost items={itemsOtherDocs}
            key={`collapse_contragents_${nomenclature.cns_id}`} />}
        {props.type == TypeModalEnum.contracts && itemsContracts}
    </Modal>;
};

export default ModalViewContract;

export function WrapModalViewContract(props: {
    isOpen: boolean |string,
    type: TypeModalEnum,
    onClose?: () => void,
    record?: TableRecord,
    errors?: ProjectError[]
}) {
    const [isModalOpen, setIsModalOpen] = useState<boolean | string>(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen( props.isOpen || true);
        }

        if (!props.isOpen && isModalOpen) {
            setIsModalOpen(false);
        }
    }, [props.isOpen]);

    function onClose() {
        console.log('onClose');
        if (getWData().cellOpen) {
            switch (props.type) {
                case 'contracts':
                    getWData().cellOpen!.isModalContragent = false;
                    break;
                case 'bills':
                    getWData().cellOpen!.isModalBill = false;
                    break;
                case 'invoices':
                    getWData().cellOpen!.isModalInvoice = false;
                    break;
                case 'way_bills':
                    getWData().cellOpen!.isModalWayBill = false;
                    break;
            }
            getWData().setRefresh(Math.random());
        }

        props.onClose?.();
    }

    async function deleteItem(type: TypeModalEnum, id: string) {
        setIsDeleting(true);
        try {
            if (type == 'contracts') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.contract, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesContractsDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                        {send_events: 1,}
                    );
                }
            } else if (type == 'invoices') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.invoice, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesInvoicesDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                        {send_events: 1,}
                    );
                }
            } else if (type == 'way_bills') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.waybill, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesWaybillsDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                        {send_events: 1,}
                    );
                }
            } else if (type == 'bills') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.bill, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesBillsDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                        {send_events: 1,}
                    );
                }
            }


            await getWData().loadDataPassport({showLoaded: false});

            getWData().cellOpen!.record = getWData().rows3.find(e => e.cns_nomenclature_id == getWData().cellOpen!.record!.cns_nomenclature_id) ?? getEmptyTableRecord();
            getWData().setRefresh(Math.random());
        } catch (e) {
            console.error((e as any)?.error);
            notification.error({message: (e as any)?.error?.message ?? 'Не удалось удалить запись'});
        }
        setIsDeleting(false);
    }

    const record2 = props.record ?? getWData().cellOpen?.record ?? getEmptyTableRecord();

    const errors2 = getLoadDataInfo().errors.filter(e => {
        if (e.nomenclature_id != record2.cns_nomenclature_id) return false;

        if (props.type == TypeModalEnum.contracts) {
            return e.type?.includes('contract');
        } else if (props.type == TypeModalEnum.invoices) {
            return e.type?.includes('invoice');
        } else if (props.type == TypeModalEnum.way_bills) {
            return e.type?.includes('waybill');
        } else if (props.type == TypeModalEnum.bills) {
            return e.type?.includes('bill');
        }

        return false;
    });


    return <ModalViewContract isOpen={isModalOpen}
                              record={record2}
                              onClose={onClose}
                              type={props.type}
                              allowEdit={tokenActions.contracts == AccessItem.FULL}
                              allowRemove={tokenActions.deletDocument == AccessItem.ALLOW}
                              errors={props.errors ?? errors2}
                              onRemoveItem={(itemId) => {

                                  Modal.confirm({
                                      closable: true,
                                      title: 'Удалить запись?',
                                      icon: <></>,
                                      footer: <div style={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          paddingTop: '20px',
                                          gridGap: '1rem',
                                      }}>
                                          <Button size={'small'} data-test={'cancel_remove_record'} onClick={() => {
                                              Modal.destroyAll();
                                          }} style={{marginRight: 'auto'}}>Отмена</Button>
                                          <Button size={'small'} type={'primary'} data-test={'do_remove_record'}
                                                  loading={isDeleting}
                                                  disabled={isDeleting}
                                                  onClick={async () => {
                                                      // add [data-test="do_remove_record"] attribute disabled
                                                      document.querySelector('[data-test="do_remove_record"]')?.setAttribute('disabled', 'true');
                                                      await deleteItem(props.type, itemId);
                                                      getWData().setRefresh(Math.random());
                                                      document.querySelector('[data-test="do_remove_record"]')?.removeAttribute('disabled');

                                                      Modal.destroyAll();
                                                  }}>Удалить</Button>
                                      </div>,
                                  });

                                  // console.log({itemId})
                              }}
                              onEditItem={(itemId) => {
                                  getWData().globalActions.isModalAddManyContracts = itemId;
                                  getWData().setRefresh(Math.random())
                              }}
    />;
}
