import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { Button, Checkbox, Col, Form, Input, Modal, notification, Row, TreeSelect } from 'antd';
import { api } from '../../../../shared/api_client';
import moment from 'moment/moment';
import { getEmptyTableRecord, getEmptyTableRecordContract, TableRecord, TableRecordContract } from '../TableRecord';
import { getPassportCache } from '../../../table_extends/loadData';
import { MyDatePicker } from '../../../../shared/stopEvent';
import { Select } from 'antd/lib';
import { filterOption } from './modal_add_contract';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTreeRows3 } from '../../../../shared/getTreeRows3';
import { modalFilterContracts, modalFilterContracts2 } from '../../../../shared/modal/modalFilterContracts';
import { modalFilterContragents } from '../../../../shared/modal/modalFilterContragents';
import ModalAddContractUpload from './modal_add_contracts_upload';
import tokenActions, { AccessItem } from '../../../../actions/tokenActions';
import { BillsFormNomenclatures, getEmptyIABills, IApiBills, IBills } from '../../../../Models/BillsForm';
import { mapNomenclature2InvoiceNomenclature } from '../../../../Models/InvoiceForm';
import { DateFormat, dateFormatter, numberFormatter } from '../../../../shared/dateFormat';
import enUS from 'antd/es/calendar/locale/en_US';
import { addProjectDocumentDb, DocumentType, isOnline, updateProjectTableRowDb } from '../../../../indexedb';
import { sizes } from '../../../../shared/sizes';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../../../i18n/localeKeys';
import { delay } from '../../../../shared/delay';
import { uniq, uniqBy } from 'lodash';
import { getFullId, getFullIdContract } from "../../../../Models/ContragentForm";


interface IModalAddGlobal {
    open: boolean | string | undefined,
    onOk: ( item: IBills ) => void | undefined,
    onCancel: () => void | undefined
}

const ModalAddBills: FunctionComponent<IModalAddGlobal> = ( props ) => {
    const { t } = useTranslation();
    const [ isSending, setIsSending ] = useState( false );
    const [ state, setState ] = useState<IBills>( getEmptyIABills() );
    const [ filteredContractsByNomenclature, setFilteredContractsByNomenclature ] = useState<TableRecordContract[]>( [] );
    const [ filteredContractsByContragents, setFilteredContractsByContragents ] = useState<TableRecordContract[]>( [] );

    const [ filteredContragents, setFilteredContragents ] = useState<DefaultOptionType[]>( [] );

    // первый фильтр по номенклатуре
    const [ selectNomenclatures, setSelectNomenclatures ] = useState<string[]>( [] );
    const [ tree, setTree ] = useState<DefaultOptionType[]>( [] );

    const rows3 = getWData().rows3;
    const rows3ByContract = useMemo( () => {
        return getWData().rows3.filter( e => e.cns_contracts.find( e2 => e2.document_id == state.document_id ) );
    }, [ state ] );

    const wayBillLines = useMemo( () => {

        // Найти такой контракт, который был выбран из списка контрактов (так как строк контракта несколько, то может найтись несколько одинаковых контрактов, привязанных к разным строкам плана)
        const contracts = rows3.map( row => row.cns_contracts.filter( contract => contract.id == state.contract_id ) ).flat();
        const contractDocumentId = contracts[0]?.document_id ?? '';

        // Получить все существующие ТТН. Причем идет работа сразу со строками ТТН, т.е. к одному document_id из ТТН может быть привязано несколько строк ТТН, в зависимости от того, сколько было выбрано номенклатур при его создании
        const allWayBills = rows3.map( e => e.cns_way_bills.map( e2 => ({ ...e2, line: e }) ) ).flat()
        // Отфильтровать из ТТН те, которые привязаны к выбранному контракту
        const wayBillsByContract = allWayBills.filter( wayBill => wayBill.parent_contract_id == contractDocumentId )
        // Отфильтровать ТТН по выбранным номенклатурам
        const wayBillByNomenclature = wayBillsByContract.filter( bill => selectNomenclatures.includes( getFullId(bill.line) ) )
        // Найти все занятые СФ
        const noFreeBillsRows = getWData().rows3.filter(row => row.cns_bills.find(bill => bill.way_bill_doc_ids?.length));
        const noFreeBills: TableRecordContract[] = [];
        for (const line of noFreeBillsRows) {
            for (const bill of line.cns_bills) {
                noFreeBills.push(({...bill, line: line}));
            }
        }

        // Оставить только свободные ТТН, не привязанные ни к одной СФ
        const freeWayBills = wayBillByNomenclature.filter( wayBill => {
            const flag = noFreeBills.find( bill => {
                return bill.way_bill_doc_ids?.includes( wayBill.document_id ?? '' ) && getFullId(wayBill.line) == getFullId(bill.line ?? getEmptyTableRecord());
            } );

            if (!flag) {
                // debugger
            }

            return !flag;
        } )

        return freeWayBills;
    }, [ state, rows3ByContract ] );

    const wayBillUniqLines = useMemo( () => {
        return uniqBy( wayBillLines, 'document_id' )
    }, [ wayBillLines ] )

    const wayBillSelectedLines = useMemo( () => {
        return wayBillLines.filter( line => state.wayBillIds.includes( line?.id ?? '' ) );
    }, [ state.wayBillIds, wayBillLines ] );

    function refreshContragents( nomenclatureIds: string[] ) {
        // console.log('refreshContragents', nomenclatureIds);

        const res = modalFilterContragents( nomenclatureIds, rows3 );

        setFilteredContragents( res );

        return Object.values( res );
    }

    function refreshContracts( nomenclatureIds: string[], contractor_id?: string ) {
        const filteredIds = filteredContragents.map( e => `${ e.value }` );

        const {
            listByContragents,
            listByNomenclature,
        } = modalFilterContracts( nomenclatureIds, contractor_id, filteredIds );

        setFilteredContractsByContragents( Object.values( listByContragents ) );
        setFilteredContractsByNomenclature( Object.values( listByNomenclature ) );

        return Object.values( listByContragents );
    }

    function resetState() {
        refreshContracts( [] );
        refreshContractsByNomenclature( [] );

        setState( getEmptyIABills() );

        setIsSending( false );
        setSelectNomenclatures( [] );
        setFilteredContractsByContragents( [] );
        setFilteredContractsByNomenclature( [] );
        setFilteredContragents( [] );

        setTree( getTreeRows3( 'bill' ) );

    }

    async function handleSave() {
        setIsSending( true );

        const payload: IApiBills = {
            bill_date: moment( state.bill_date, 'YYYY-MM-DD' ).format( 'DD.MM.YYYY' ),
            bill_number: state.bill_number,
            contractor_id: state.contractor_id ?? '',
            nomenclatures: state.wayBillNomenclatures.map( e => ({
                nomenclature_id: e.nomenclature_id,
                price: e.price,
                volume: e.volume,
                amount: e.amount,
                type_id: e.type_id,
                project_id: e.project_id,
                section_id: e.section_id,
            }) ),
            contract_id: state.document_id,
            file_ids: state.files.map( e => e.id ),
            waybill_document_ids: wayBillSelectedLines.map( e => e?.document_id ?? '' ).filter( e => e ),
        };

        if ( isOnline ) {
            try {
                const res = await api.documents.billsCreate( { ...payload, contractor_id: undefined } as any );

                resetState();
                props.onOk( state );

                notification.success( { message: t( localeKeys.table.add.bill.created ) } );
            } catch ( e ) {
                notification.error( { message: (e as any)?.error?.message ?? t( localeKeys.table.add.bill.createError ) } );
            }
        } else {
            const contractor = `${ filteredContragents.find( e => e.value == state.contractor_id )?.label }`;
            const id = Math.random().toString();
            await addProjectDocumentDb( id, DocumentType.bill, {
                ...payload,
                contractor: contractor,
                files: state.files,
            } );

            for ( let item of state.wayBillNomenclatures ) {
                const row = rows3.find( e => getFullId(e) == getFullIdContract(item) ) ?? getEmptyTableRecord();

                row.cns_bills.push( {
                    id: id,
                    project_contract_data_id: state.contract_id ?? '',
                    document_id: Math.random().toString(),

                    parent_id: row.cns_nomenclature_id ?? '',

                    parent_contract_id: filteredContractsByContragents.find( e => e.contragent_id == state.contractor_id )?.document_id ?? '',

                    contragent: contractor,
                    contragent_id: state.contractor_id ?? '',

                    status: '',

                    size: `${ item.volume }`,
                    price: `${ item.price }`,
                    sum_wat: `${ item.amount }`,

                    doc_number: state.bill_number,
                    doc_date: state.bill_date,

                    files: state.files.length,

                    documents: state.files.map( file => ({
                        id: file.id,
                        name: file.name,
                        url: file.id,
                        path: file.id,
                    }) ),

                    date_start: '',
                    date_end: '',
                } );

                row.cns_bill_number = row.cns_bills.length > 1 ? row.cns_bills.length : payload.bill_number as any;
                row.cns_bill_size = row.cns_way_bills.reduce( ( sum, waybill ) => sum + Number( waybill.size ), 0 );
                row.cns_bill_price = row.cns_way_bills.reduce( ( sum, waybill ) => sum + Number( waybill.price ), 0 );
                row.cns_bill_sum = row.cns_way_bills.reduce( ( sum, waybill ) => sum + Number( waybill.sum_wat ), 0 );
                row.cns_bill_date = state.bill_date;

                await updateProjectTableRowDb( getPassportCache().id ?? '', row );
            }


            notification.info( { message: t( localeKeys.table.add.bill.saveOfflineWarning ) } );

            getWData().setRefresh( Math.random() );
            resetState();
            props.onOk( state );
        }

        setIsSending( false );
    }

    function onChangeTree( nomenclatureIds: string | string[] ) {
        if ( Array.isArray( nomenclatureIds ) ) {
            setSelectNomenclatures( nomenclatureIds );

            refreshContracts( nomenclatureIds );
            refreshContractsByNomenclature( nomenclatureIds );
        }

        setState( { ...state, wayBillNomenclatures: [], contractor_id: undefined, contract_id: undefined, files: [] } );
    }

    function updateBillLines( contract_id: string ) {
        console.log( 'updateBillLines', contract_id );
        const allContracts = rows3.map( item => item.cns_contracts ).flat();
        const contract = allContracts.find( e => e.id == contract_id );
        const nomenclatures: TableRecord[] = rows3
            .filter( e => e.cns_contracts.find( e2 => e2.document_id == contract?.document_id ) && selectNomenclatures.includes( getFullId(e) ) );

        const allContracts2 = nomenclatures.map( item => item.cns_contracts.find( e => e.document_id == contract?.document_id ) ?? getEmptyTableRecordContract() );

        const nomenclatures2: BillsFormNomenclatures[] = allContracts2
            .map( ( e, index ) => mapNomenclature2InvoiceNomenclature( nomenclatures[index], e as any, t as any ) );

        setState( {
            ...state,
            contract_id: contract_id,
            contractor_id: contract?.contragent_id ?? '',
            document_id: contract?.document_id ?? '',
            wayBillNomenclatures: nomenclatures2,
        } );

    }

    const isDisabled = !state.bill_number || !state.bill_date || !state.contract_id || state.wayBillNomenclatures.length == 0 || state.files.length == 0;

    useEffect( () => {
        resetState();
        delay( 100 ).then( async _ => {
            if ( props.open && typeof props.open === 'string' ) {
                const nomenclature = getWData().rows3.find( e => e.cns_contracts.find( e2 => e2.id == props.open ) );
                const contract = nomenclature?.cns_contracts.find( e => e.id == props.open );

                if ( nomenclature && contract ) {
                    setSelectNomenclatures( [ getFullId(nomenclature) ] );

                    refreshContracts( [ getFullId(nomenclature) ], `${ contract.contragent_id }` );
                    refreshContractsByNomenclature( [ getFullId(nomenclature) ] );
                    setState( {
                        ...state,
                        document_id: contract.document_id,
                        contract_id: contract.id,
                        contractor_id: contract?.contragent_id ?? '',
                        wayBillNomenclatures: [
                            mapNomenclature2InvoiceNomenclature( nomenclature, contract, t as any ),
                        ],
                    } );
                }
            }
        } );
    }, [ props.open ] );

    function refreshContractsByNomenclature( selectNomenclatures: string[] ) {
        const contragents = refreshContragents( selectNomenclatures );

        if ( contragents?.length == 1 ) {
            setState( { ...state, contractor_id: `${ contragents[0].value }` } );
            refreshContracts( selectNomenclatures, `${ contragents[0].value }` );
        } else {
            setState( { ...state, contractor_id: undefined } );
            refreshContracts( selectNomenclatures, undefined );
        }
    }

    useEffect( () => {
        if ( selectNomenclatures && state.contractor_id ) {
            refreshContracts( selectNomenclatures, state.contractor_id );

            const contracts = refreshContracts( selectNomenclatures, `${ state.contractor_id }` );

            if ( contracts?.length == 1 ) {
                updateBillLines( contracts[0].id );
            }
        }
    }, [ state.contractor_id ] );

    const isMobile = window.outerWidth < sizes.mobile;

    (window as any).modalAddBill = {
        state: state,
        isDisabled: isDisabled,
        tree: tree,
        selectNomenclatures: selectNomenclatures,
        isSending: isSending,
        filteredContragents: filteredContragents,
        filteredContractsByContragents: filteredContractsByContragents,
        filteredContractsByNomenclature: filteredContractsByNomenclature,
        wayBillLines,
        wayBillUniqLines,
        wayBillSelectedLines,
        props,
        rows3ByContract,
    };

    const btnCreate = <Button
        data-test={'BTN_ADD_BILL'}
        type={ 'primary' } onClick={ handleSave } disabled={ isDisabled }
        loading={ isSending }> { t( localeKeys.table.add.bill.save ) } </Button>;

    return <>
        <Modal title={ t( localeKeys.table.add.bill.title ) }
               open={ !!props.open }
               onCancel={
                   () => {
                       resetState();
                       props.onCancel();
                   } }
               destroyOnClose={ true }
               className="modal-add-contract"
               footer={ <>
                   <Button
                       type={ 'link' }
                       onClick={ () => {
                           resetState();
                           props.onCancel();
                       } }> { t( localeKeys.shared.cancelBtn ) } </Button>
                   { btnCreate }

               </> }

        >
            <Form layout={ isMobile ? 'vertical' : 'horizontal' }>
                <Row gutter={ isMobile ? { sm: 10 } : undefined }>
                    <Col span={ isMobile ? 24 : 14 }>
                        <Form.Item
                            label={ t( localeKeys.table.add.invoice.number ) + ':' }
                            labelCol={ { span: 10 } }
                        >
                            <Input
                                data-test={ 'INPUT_BILL_NUMBER' }
                                placeholder={ t( localeKeys.table.add.invoice.number ) } onInput={ val => {
                                setState( { ...state, bill_number: (val.target as any).value } );
                            } }/>
                        </Form.Item>
                    </Col>
                    <Col span={ isMobile ? 24 : 10 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.date ) } labelCol={ { span: 6 } }>
                            <MyDatePicker
                                data-test={ 'INPUT_BILL_DATE' }
                                locale={ enUS }
                                style={ { maxWidth: isMobile ? '100%' : '137' } }
                                format={ DateFormat }
                                defaultValue={ moment( state.bill_date, 'YYYY-MM-DD' ) }
                                onChange={ ( dates, dateStrings ) => {
                                    setState( {
                                        ...state,
                                        bill_date: moment( `${ dateStrings }`, DateFormat ).format( 'YYYY-MM-DD' ),
                                    } );
                                } }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.nomenclatures ) } labelCol={ { span: 6 } }>
                            <TreeSelect
                                data-test={ 'INPUT_BILL_NOMENCLATURES' }
                                placeholder={ t( localeKeys.table.add.invoice.selectRows ) }
                                treeCheckable={ true }
                                showSearch
                                value={ selectNomenclatures }
                                dropdownStyle={ { maxHeight: 400, overflow: 'auto' } }
                                allowClear
                                treeDefaultExpandAll={ getWData().rows3.length < 100 }
                                onChange={ onChangeTree }
                                treeData={ tree }
                                filterTreeNode={ ( input, treeNode ) => !!treeNode.label?.toString().toLowerCase().includes( input.toLowerCase() ) }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.contractor ) } labelCol={ { span: 6 } }>
                            <Select
                                data-test={ 'INPUT_BILL_CONTRACTOR' }
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                value={ state.contractor_id }
                                disabled={ selectNomenclatures.length == 0 }
                                filterOption={ filterOption }
                                options={ filteredContragents.map( e => {
                                    const { listByContragents } = modalFilterContracts2( selectNomenclatures, `${ e.value }` );

                                    return ({
                                        label: Object.values( listByContragents ).length ? t( localeKeys.table.add.invoice.labelWithCount, {
                                            label: e.label,
                                            count: Object.values( listByContragents ).length,
                                        } ) : t( localeKeys.table.add.invoice.labelWithoutCount, { label: e.label } ),
                                        value: e.value,
                                    });
                                } ) ?? [] }
                                onChange={ val => {
                                    setState( {
                                        ...state,
                                        contractor_id: val,
                                        contract_id: undefined,
                                        wayBillNomenclatures: [],
                                    } );
                                } }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.contract ) } labelCol={ { span: 6 } }>
                            <Select
                                data-test={ 'INPUT_BILL_CONTRACT' }
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                value={ state.contract_id }
                                options={ filteredContractsByContragents.map( ( item ) => ({
                                    value: item.id ?? '',
                                    label: item.doc_number ? t( localeKeys.table.add.invoice.titleDoc, {
                                        doc_number: item.doc_number,
                                        doc_date: dateFormatter( item.doc_date ),
                                        sum_wat: item.sum_wat,
                                    } ) : `${ dateFormatter( item.doc_date ) } ${ item.size }x${ item.price }, ${ item.sum_wat }`,
                                }) ) }
                                disabled={ state.contractor_id == undefined }
                                filterOption={ filterOption }
                                onChange={ updateBillLines }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ 'ТТН' } labelCol={ { span: 6 } }>
                            <div className={ 'modal-add-bill__waybills' }>
                                { !wayBillUniqLines.length &&
                                    <div className={ 'modal-add-bill__waybill-line' }>Нет ТТН</div> }
                                { wayBillUniqLines
                                    .map( wayBill => {
                                        // console.log(wayBillIsUsed);
                                        return <div className={ 'modal-add-bill__waybill-line' }>
                                    <span>
                                        <Checkbox
                                            disabled={ !!getWData().errors.find( error => error.error?.waybill?.id == wayBill?.id && !error.closed_at ) }
                                            value={ state.wayBillIds?.includes( wayBill?.id ?? '' ) }
                                            onChange={ ( e ) => {
                                                const allIds = wayBillLines.filter( wayBill2 => wayBill2.document_id == wayBill.document_id ).map( e => e.id )

                                                if ( e.target.checked ) {
                                                    setState( {
                                                        ...state,
                                                        wayBillIds: [ ...state.wayBillIds, ...allIds ]
                                                    } );
                                                } else {
                                                    setState( {
                                                        ...state,
                                                        wayBillIds: state.wayBillIds.filter( id => !allIds.includes( id ) )
                                                    } )
                                                }

                                            } }/> { wayBill?.doc_number }
                                    </span>
                                            { dateFormatter( wayBill?.doc_date ?? '' ) }
                                        </div>;
                                    } ) }
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col span={ isMobile ? 24 : 18 } offset={ isMobile ? 0 : 6 }>
                    { selectNomenclatures.length != 0 && <div className={ 'modal-add-contract__list' }>
                        { selectNomenclatures.map( nomenclatureId => {
                            const nomenclature = rows3.find( item => getFullId( item ) == nomenclatureId );
                            const wayBillLines2 = wayBillSelectedLines.filter( wayBill => getFullId( wayBill.line ) == nomenclatureId );


                            const sizes = wayBillLines2.reduce( ( acc, item ) => acc + (Number( item?.size ?? 0 )), 0 );
                            const sum = wayBillLines2.reduce( ( acc, item ) => acc + (Number( item?.sum_wat ?? 0 )), 0 );
                            const price = sum / (sizes || 1);

                            return <>
                                <div className={ 'modal-add-bill__nomenclature' }>{ nomenclature?.cns_title }</div>
                                <div
                                    className={ 'modal-add-bill__nomenclature_row' }>{ numberFormatter( sizes ) } { nomenclature?.cns_ed_izm } x { numberFormatter( price ) } ₽
                                    = { numberFormatter( sum ) } ₽
                                </div>
                            </>;
                        } ) }
                    </div> }
                </Col>
            </Row>

            <Row>
                <Col span={ isMobile ? 24 : 18 } offset={ isMobile ? 0 : 6 }>
                    <ModalAddContractUpload
                        width={ '170px' }
                        files={ state.files }
                        onUploaded={ fileUploaded => {
                            setState( { ...state, files: fileUploaded } );
                        } }
                        disabled={ tokenActions.contracts != AccessItem.EDIT && tokenActions.contracts != AccessItem.FULL }
                    />
                </Col>
            </Row>

        </Modal>

    </>;
};

export default ModalAddBills;

export function ModalAddBillsBind( props: { isOpen: boolean | string } ) {
    const [ isModalOpen, setIsModalOpen ] = useState<boolean | string>( false );
    const showModal = () => {
        setIsModalOpen( props.isOpen || true );
    };
    const handleCancel = () => {
        setIsModalOpen( false );
        getWData().globalActions.isModalAddBills = false;
    };
    const handleOk = () => {
        getWData().globalActions.isModalAddBills = false;
        setIsModalOpen( false );
    };

    useEffect( () => {
        if ( props.isOpen ) {
            showModal();
        }
    }, [ props.isOpen ] );

    return <ModalAddBills open={ isModalOpen } onOk={ handleOk } onCancel={ handleCancel }/>;
}
