import { CloseOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Table,
    Tabs,
    TabsProps,
    Upload,
    UploadProps,
} from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { HelperValue } from '../../ApiEpo';
import { contragentKey, getEmptyContragentView, IContragentView } from '../../Models/IContragent';
import { useContragentsLocations } from '../../actions/useContragentsLocations';
import { useContragentsTags } from '../../actions/useContragentsTags';
import { useUsers } from '../../actions/useUsers';
import { doDownloadFile } from '../../shared/DownloadFile';
import { apiAuth, directoryApi, hostDirectory } from '../../shared/api_client';
import { MyDatePicker } from '../../shared/stopEvent';
import { getValueStore } from '../../shared/store';
import { base642file, getBase64, uploadFile, uploadFileAsync } from './uploadFile';
import { sizes } from '../../shared/sizes';
import { DateFormat } from '../../shared/dateFormat';
import enUS from 'antd/es/calendar/locale/en_US';
import { addContragent, isOnline, removeContragents, updateContragent } from '../../indexedb';
import { ContractorShort, CreateContractorRequest, TableRow } from '../../ApiDirectory';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../i18n/localeKeys';
import contragentListModalColumns, { contragentListModalId } from './ContragentListModalColumns';
import { useNomenclaturesTable } from '../../actions/useNomenclaturesTable';
import { useLocationList } from '../../actions/useLocationList';
import ModalConnectNomenclaturesContent from '../canvas_table/components/modals/ModalConnectNomenclaturesContent';
import { getWData } from '../table_extends/getWData';


const ContragentListModal: FunctionComponent<{
    isOpen: boolean;
    close: () => void;
    localItem: ContractorShort;
    onlyView: boolean;
    onSuccess: () => void;
    types: {
        /** @format uuid */
        value?: string;
        label?: string;
    }[];
    rating: {
        value?: string;
        label?: string;
    }[];
}> = (props) => {
    const isMobile = window.innerWidth < sizes.mobile;
    const { t } = useTranslation();

    const { users: usersList, usersLoading } = useUsers();
    const [newContragent, setNewContragent] = useState<IContragentView>({ ...getEmptyContragentView() });
    const [form] = Form.useForm();
    const [searchLocation, setSearchLocation] = useState('');
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const [searchTags, setSearchTags] = useState('');
    const { contragentsTags, contragentsTagsLoading, contragentsTagsError } = useContragentsTags({
        search: searchTags,
    });
    const { contragentsLocation, contragentsLocationLoading, contragentsLocationError } = useContragentsLocations({
        search: searchLocation,
    });

    const [comment, setComment] = useState('');
    const [contacts, setContact] = useState('');
    const [relatedUser, setRelatedUser] = useState<string>('');
    const [searchUser, setSearchUser] = useState('');

    const [uploading, setUploading] = useState(false);
    const [stateNomenclatures, setStateNomenclatures] = useState<{
        [keyNomenclature: string]: {
            locationIds: string[]
        }
    }>({});

    const documents: UploadFile[] = useMemo(
        () =>
            newContragent.documents
                .filter((e) => !e.remove)
                .map((e) => ({
                    name: e.name,
                    uid: e.id,
                    url: e.url,
                    status: 'done',
                })),
        [newContragent],
    );

    const propsUpload: UploadProps = {
        id: `up_${props.localItem?.id}`,
        action: `${hostDirectory}/api/files/upload`,
        headers: { Authorization: `Bearer ${getValueStore('token')}` },
        customRequest: async (config) => {
            const indexNewFile = newContragent.documents.findIndex((e) => e.id.includes('rc-upload-'));
            if (isOnline) {
                return uploadFile(config, (res) => {
                    console.log({ res, docs: newContragent.documents });

                    newContragent.documents.splice(indexNewFile, 1);
                    newContragent.documents.unshift({
                        uid: res.id,
                        id: '',
                        file_id: res.file_id ?? res.id,
                        name: res.name,
                        url: res.url,
                        remove: false,
                    });

                    setUploading(false);
                    setNewContragent({ ...newContragent });
                });
            } else {
                const base64 = await getBase64(config.file as any);

                newContragent.documents.splice(indexNewFile, 1);
                newContragent.documents.unshift({
                    uid: base64,
                    id: '',
                    file_id: base64,
                    name: (config.file as any)?.name ?? '',
                    url: base64,
                    remove: false,
                });

                setUploading(false);
                setNewContragent({ ...newContragent });

            }
        },
        onChange({ file, fileList }) {
            console.log({ file, fileList, documents: newContragent.documents });
            if (file.status == 'uploading') {
                newContragent.documents.push({
                    uid: file.uid,
                    id: file.uid,
                    file_id: '',
                    name: file.name,
                    url: file.url ?? '',
                    remove: false,
                });

                setUploading(true);
                // setNewContragent({...newContragent});
            }
            if (file.status == 'done') {
                const indexNewFile = newContragent.documents.findIndex((e) => e.id.includes('rc-upload-'));
                newContragent.documents.splice(indexNewFile, 1);

                // setNewContragent({...newContragent});
            }
        },
        onRemove: async (file) => {
            console.log({ file });
            if ((file).uid.includes('__AUTO_')) {
                setNewContragent({
                    ...newContragent,
                    documents: [...newContragent.documents.filter((e) => e.url != file.url)],
                });
            } else {
                setNewContragent({
                    ...newContragent,
                    documents: [
                        ...newContragent.documents.map((e) => ({ ...e, remove: e.remove || e.id === file.uid })),
                    ],
                });
            }
        },
        fileList: documents,
        itemRender: (originNode, file) => {
            return (<div className={'contragent-file-list'}>
                    <div
                        onClick={async (e) => {
                            await doDownloadFile(file.url ?? '', file.name ?? '');
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }}
                    >
                        {file.name}
                    </div>
                    {!props.onlyView && <DeleteOutlined onClick={() => {
                        propsUpload.onRemove?.(file);
                    }} />}
                </div>
            );
        },
    };

    useEffect(() => {
        setRelatedUser('');
        setComment('');
        setContact('');

        resetForm();

        if (props.localItem.id == '-1') {
            setNewContragent({ ...getEmptyContragentView() });
            form.setFieldsValue({ ...getEmptyContragentView() });
        } else {
            setNewContragent({ ...getEmptyContragentView() });

            let nomenclatures2: { [id: string]: { locationIds: string[] } } = {};
            let nomenclatures3: { [id: string]: { locationIds: string[] } } = {};

            for (let item of props.localItem.nomenclatures ?? []) {
                if (item.nomenclature_id) {
                    nomenclatures2[item.nomenclature_id] = {locationIds: item.region_ids}
                }
            }

            for (const row of nomenclatures?.rows ?? []) {
                if ((row.row_type == 'nomenclature') && nomenclatures2[row.nomenclature_id ?? '']) {
                    nomenclatures3[contragentListModalId(row)] = { locationIds: [ ...nomenclatures2[row.nomenclature_id ?? ''].locationIds ] }
                    nomenclatures3[`${row.section_id}_${row.type_id}`] = { locationIds: [] }
                    nomenclatures3[row.section_id ?? ''] = { locationIds: [] }
                }
            }

            setStateNomenclatures(nomenclatures3);

            form.setFieldsValue({ ...getEmptyContragentView() });

            const e = props.localItem;

            const c: IContragentView = {
                ...e,
                id: e.id ?? '',
                name: e.name ?? '',
                inn: e.inn ?? '',
                ogrn: e.ogrn ?? '',
                type_id: e.type?.id ?? '',
                doc_number: e.doc_number ?? '',
                doc_date: moment(e.doc_date, 'YYYY-MM-DD'),
                contacts: e.contacts ?? '',
                email: e.email ?? '',

                rating: `${e.rating}`,
                comment: e.comment ?? '',
                legal_address: e.legal_address ?? '',
                postal_address: e.postal_address ?? '',
                phone: e.phone ?? '',
                user_uid: e.related_user ?? '',
                documents:
                    e.documents?.map((e) => ({
                        name: e.name ?? '',
                        url: e.link ?? '',
                        id: e.id ?? '',
                        remove: false,
                        file_id: e.id ?? '',
                    })) ?? [],
                requisites:
                    e.requisites?.map((e) => ({
                        id: e.id ?? '',
                        bank_name: e.bank_name ?? '',
                        account: e.account ?? '',
                        bik: e.bik ?? '',
                        correspondent_account: e.correspondent_account ?? '',
                        kpp: e.kpp ?? '',
                        remove: false,
                    })) ?? [],
                nomenclatures: e.nomenclatures ?? [],
            };
            if (e) {
                console.log(e);
                setNewContragent(c);
                form.setFieldsValue({
                    ...c,
                });
                setComment(e.comment ?? '');
                setContact(e.contacts ?? '');
                setRelatedUser(e.related_user ?? '');
            }
        }
    }, [props.localItem.id]);

    async function resetForm() {
        console.log('resetForm');
        form.resetFields();
        form.setFieldValue('doc_number', '');

        await queryClient.invalidateQueries(contragentKey);
        setSearchLocation('');
        setSearchUser('');
    }

    async function handleClose() {
        props.close();
        await resetForm();
        setNewContragent({ ...getEmptyContragentView(), id: '-1' });
    }

    const handleSave = async () => {
        if (props.onlyView) {
            props.close();
            return;
        }

        const values = await form.validateFields();

        setLoading(true);

        values.comment = comment;
        values.related_user = relatedUser;
        values.contacts = contacts;

        if (values.rating) {
            values.rating = Number((values.rating as any).value ?? values.rating) as 1 | 2 | 3;
        }
        if (values.type_id) {
            values.type_id = (values.type_id as any).value;
        }
        if (values.tags?.length) {
            values.tags = values.tags.map((e: any) => e.value ?? e);
        }
        if (values.location?.length) {
            values.location = values.location.map((e: any) => e.value ?? e);
        }

        if (values.requisites?.length) {
            values.requisites = values.requisites
                .filter((e: any) => !(e.remove && !e.id))
                .map((e: any) => ({
                    id: e.id,
                    bank_name: e.bank_name,
                    account: e.account,
                    bik: e.bik,
                    correspondent_account: e.correspondent_account,
                    kpp: e.kpp,
                    remove: e.remove ? true : undefined,
                }));
        }

        if (values.doc_date) {
            values.doc_date = ((values.doc_date as any) as Moment).format('YYYY-MM-DD');
        }

        values.documents = newContragent.documents.map((e, index) => {
            const fileId = (props.localItem.documents?.[index] as any)?.file_id;
            return ({
                id: e.id ? e.id : undefined,
                file_id: e.uid ?? fileId,
                remove: e.remove ? true : undefined,
                name: fileId ? undefined : e.name,
            });
        });

        values.nomenclatures = [];
        for (const id in stateNomenclatures) {
            const [sectionId, typeId, nomenclatureId] = id.split('_');
            if (nomenclatureId) {
                if (!values.nomenclatures.find((row2: {nomenclature_id: string, location_ids: string[]}) => row2.nomenclature_id === nomenclatureId)) {
                    values.nomenclatures.push({
                        nomenclature_id: nomenclatureId,
                        region_ids: stateNomenclatures[id].locationIds,
                    });
                }
            }
        }


        try {
            if (props.localItem.id?.includes('.')) {
                if (isOnline) {
                    const documents = [...values.documents];

                    for (let indexDoc in documents ?? []) {
                        let doc = props.localItem.documents![indexDoc];

                        if ((doc as any).file_id?.includes('base64')) {
                            const res = await uploadFileAsync(base642file((doc as any).file_id, doc.name!));
                            documents[indexDoc] = { file_id: res.id } as any;
                        }
                    }

                    const res = (await directoryApi.contractors.contractorsUpdate(props.localItem.id, {
                        ...values,
                        documents,
                    })) as any;
                    if (res.data.name) {
                        message.success(t(localeKeys.contragentsListScreen.modal.contragentUpdated));
                        await resetForm();
                        props.onSuccess();
                        props.close();
                    }

                    await removeContragents(props.localItem);
                } else {
                    await addContragent({ ...props.localItem, ...values }, { isUpdate: true });
                    message.info(t(localeKeys.contragentsListScreen.modal.contragentCreatedAfterInternet));
                    await resetForm();
                    props.onSuccess();
                    props.close();
                }
            } else if (props.localItem.id == '-1') {
                if (isOnline) {
                    let documents = [];

                    for (let doc of values.documents) {
                        if (!doc.remove) {
                            documents.push({ file_id: doc.file_id });
                        }
                    }

                    const res = (await directoryApi.contractors.contractorsCreate({ ...values, documents })) as any;

                    console.log({ res });

                    if (res.data.name) {
                        message.success(t(localeKeys.contragentsListScreen.modal.contragentCreated));
                        await resetForm();
                        props.onSuccess();
                        props.close();
                        // setTimeout(() => navigate(`/passport/${res.data.id}`), 1000)
                    }

                    if (res.errors || res.message) {
                        message.error(`${res.message ?? res.errors?.message ?? JSON.stringify(res.errors)}`);
                    }
                } else {
                    message.info(t(localeKeys.contragentsListScreen.modal.contragentCreatedAfterInternet));

                    await addContragent({ id: `${Math.random()}`, ...values });

                    await resetForm();
                    props.onSuccess();
                    props.close();
                }
            } else {
                if (isOnline) {
                    for (const index in values.documents) {
                        delete values.documents[index].name;
                    }
                    const res = (await directoryApi.contractors.contractorsUpdate(`${props.localItem.id}`, values)) as any;

                    if (res.errors || res.message) {
                        message.error(`${res.message ?? res.errors?.message ?? JSON.stringify(res.errors)}`);

                        console.log({ res });
                    } else {
                        await resetForm();
                        props.onSuccess();
                        props.close();
                        await updateContragent({ ...props.localItem, ...values }, true);
                    }
                } else {
                    message.info(t(localeKeys.contragentsListScreen.modal.contragentCreatedAfterInternet));

                    await updateContragent({ ...props.localItem, ...values });

                    await resetForm();
                    props.onSuccess();
                    props.close();
                }
            }
        } catch (e: any) {
            console.log(e);
            if (e.message?.startsWith('Network')) {
                message.info(t(localeKeys.contragentsListScreen.modal.contragentCreatedAfterInternet));

                await updateContragent({ ...props.localItem, ...values });

                await resetForm();
                props.onSuccess();
                props.close();

            } else {
                if (e.error) {
                    const err = e.error?.message ?? e.error.errors;
                    message.error(`${err ?? t(localeKeys.shared.errorRequest)}`);
                } else if (e instanceof Response) {
                    message.error(t(localeKeys.shared.errorRequest));
                    // const res = await e.json()
                    // message.error(`${res.message ?? res.errors?.message ?? JSON.stringify(res.errors)}`)
                }
                if (e.errors || e.message) {
                    message.error(`${e.message ?? e.errors?.message ?? JSON.stringify(e.errors)}`);
                }
            }
        }
        setLoading(false);
        await queryClient.invalidateQueries(contragentKey);
    };

    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<HelperValue[]>([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            apiAuth.helpers.usersList({ search: value, page: 1, per_page: 15, only_active: 1 }).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setOptions(newOptions.data.data ?? []);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 800);
    }, [searchUser, props.localItem]);

    useEffect(() => {
        console.log('searchUser', searchUser);
        if (searchUser) {
            debounceFetcher(searchUser);
        }
    }, [searchUser]);

    const tabs: TabsProps['items'] = [
        {
            key: 'main',
            label: 'Основное',
            children: <></>,
        },
        {
            key: 'other',
            label: 'Номенклатуры',
            children: <></>,
        },
    ];

    const [activeTab, setActiveTab] = useState('main');

    const {
        nomenclatures,
        nomenclaturesLoading,
        nomenclaturesError,
        nomenclaturesClearCache,
    } = useNomenclaturesTable();
    const {locations} = useLocationList()
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const nomenclaturesRows: (TableRow & { hasChildren: boolean })[] = useMemo( () => {
        const selectNomenclatures = Object.keys(stateNomenclatures);
        return nomenclatures?.rows
            .filter(row => {
                const id = contragentListModalId(row)
                return selectNomenclatures.includes(id);
            })
            .map(row => {
                if (row.row_type == 'section') {
                    const hasChildren = !!nomenclatures?.rows.find(row2 => row2.section_id == row.section_id && row2.row_type == 'work_type');
                    return {
                        ...row,
                        hasChildren,
                    };
                }

                if (row.row_type == 'work_type') {
                    const hasChildren = !!nomenclatures?.rows.find(row2 => `${row2.section_id}_${row2.type_id}` == `${row.section_id}_${row.type_id}` && row2.row_type == 'nomenclature');
                    return {
                        ...row,
                        hasChildren,
                    };
                }

                return {
                    ...row,
                    hasChildren: false,
                };
            })
            .filter(row => {
                if (row.row_type == 'section') return true;

                if (row.row_type == 'work_type') {
                    if (expandedRowKeys.includes(row.section_id!)) {
                        return true;
                    }
                }

                if (row.row_type == 'nomenclature') {
                    if (expandedRowKeys.includes(`${row.section_id}_${row.type_id}`)) {
                        return true;
                    }
                }

                return false;
            }) ?? [];
    }, [ nomenclatures, expandedRowKeys, stateNomenclatures ] );

    useEffect(() => {
        if (nomenclatures?.rows) {
            getWData().db.nomenclaturesStruct = nomenclatures.rows;
        }
    }, [nomenclaturesLoading]);

    function addNomenclature() {
        const modal = Modal.confirm({
            className: 'modal-connect-nomenclatures',
            title: 'Справочник номенклатур',
            icon: <></>,
            closable: true,
            closeIcon: <CloseOutlined />,
            content: <ModalConnectNomenclaturesContent
                closeModal={() => modal.destroy()}
                defaultSelect={Object.keys(stateNomenclatures)}
                onAction={async selected => {
                    let state: {[nomenclatureId: string]: {locationIds: string[]}} = {};

                    for (const nomenclatureId of selected) {
                        state[nomenclatureId] = {
                            locationIds: stateNomenclatures[nomenclatureId]?.locationIds ?? []
                        }
                    }

                    setStateNomenclatures(state)

                    modal.destroy();
                    return Promise.resolve();
                }}
            />,
            footer: <></>,
        });
    }

    function handleRemoveNomenclature( record: TableRow ) {
        const id = contragentListModalId(record);

        const state = {...stateNomenclatures};
        delete state[id];

        setStateNomenclatures(state);
    }

    function updateLocationsByNomenclature(record: TableRow, locationIds: string[]) {
        const nomenclatureId = record.nomenclature_id ?? '';
        const state = {...stateNomenclatures};
        for (let id2 in stateNomenclatures) {
            if (id2.includes(nomenclatureId)) {
                state[id2] = {
                    locationIds
                }
            }
        }
        setStateNomenclatures(state);
    }

    (window as any).modalContragent = {
        newContragent,
        setNewContragent,
        form,
        searchLocation,
        setSearchLocation,
        setOptions,
        options,
        fetching,
        setFetching,
        searchUser,
        setSearchUser,
        contragentsLocation,
        stateNomenclatures,
        nomenclaturesRows,
    };

    return (
        <Modal
            width={750}
            title={
                props.localItem.id != '-1'
                    ? props.onlyView
                        ? t(localeKeys.contragentsListScreen.modal.viewContragent)
                        : t(localeKeys.contragentsListScreen.modal.editContragent)
                    : t(localeKeys.contragentsListScreen.modal.addContragent)
            }
            open={props.isOpen}
            onOk={handleSave}
            onCancel={handleClose}
            okText={props.localItem.id != '-1' ? (props.onlyView ? t(localeKeys.shared.closeBtn) : t(localeKeys.shared.saveBtn)) : t(localeKeys.contragentsListScreen.modal.createContragent)}
            confirmLoading={loading}
            wrapClassName={`create_contragent_modal ${props.onlyView ? 'onlyView' : ''} ${isMobile ? 'viewMobile' : ''}`}
            afterClose={() => {
                setNewContragent({ ...getEmptyContragentView() });
                form.resetFields();
            }}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                initialValues={{
                    ...newContragent,
                }}
                onFinish={handleSave}
                disabled={loading || props.onlyView}
            >

                <Tabs defaultActiveKey="main" items={tabs} onChange={tab => setActiveTab(tab)} />

                <span style={{ display: activeTab == 'main' ? 'block' : 'none' }}>



                <Form.Item
                    label={t(localeKeys.contragentsListScreen.modal.fields.name)}
                    name="name"
                    rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                    data-test="name"
                >
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                <Row>
                    <Col span={props.onlyView ? 24 : 16}>
                        <Form.Item
                            label={t(localeKeys.contragentsListScreen.modal.fields.contractNumber)}
                            name={props.onlyView ? undefined : 'doc_number'}
                            rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                            data-test="doc_number"
                            labelCol={props.onlyView ? undefined : { span: 12 }}
                        >
                            {!props.onlyView && (
                                <Input
                                    variant={props.onlyView ? 'borderless' : 'outlined'}
                                    disabled={loading || props.onlyView}
                                    placeholder={t(localeKeys.contragentsListScreen.modal.fields.contractNumberValue)}
                                />
                            )}

                            {props.onlyView && (
                                <Input
                                    variant={'borderless'}
                                    value={`${newContragent.doc_number} / ${newContragent.doc_date?.format?.(DateFormat)} `}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    {!props.onlyView && (
                        <Col span={8}>
                            <Form.Item
                                wrapperCol={{ span: 24 }}
                                name="doc_date"
                                rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                                data-test="doc_date"
                            >
                                <MyDatePicker
                                    locale={enUS}
                                    variant={props.onlyView ? 'borderless' : 'outlined'}
                                    disabled={loading || props.onlyView}
                                    placeholder={'Дата договора'}
                                    format={DateFormat}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Form.Item
                    label={t(localeKeys.contragentsListScreen.modal.fields.type)}
                    name="type_id"
                    rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                    data-test="type"
                >
                    <Select
                        variant={props.onlyView ? 'borderless' : 'outlined'}
                        disabled={loading || props.onlyView}
                        labelInValue
                        options={props.types.map((item) => ({
                            label: item.label,
                            value: item.value,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.contragentsListScreen.modal.fields.region)}
                    name="location"
                    data-test="location"
                    rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                >
                    <Select
                        variant={props.onlyView ? 'borderless' : 'outlined'}
                        placeholder={contragentsLocationLoading ? t(localeKeys.shared.loading) : ''}
                        disabled={loading || props.onlyView}
                        labelInValue
                        mode="tags"
                        allowClear={true}
                        onSearch={(values) => setSearchLocation(values.trim())}
                        options={contragentsLocation?.data?.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.contragentsListScreen.modal.fields.rating)}
                    name="rating"
                    rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                    data-test="rating"
                >
                    <Select
                        variant={props.onlyView ? 'borderless' : 'outlined'}
                        disabled={loading || props.onlyView}
                        labelInValue
                        options={props.rating}
                    />
                </Form.Item>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.material)} name="tags"
                           data-test="tags">
                    <Select
                        variant={props.onlyView ? 'borderless' : 'outlined'}
                        placeholder={contragentsTagsLoading ? t(localeKeys.shared.loading) : ''}
                        disabled={loading || props.onlyView}
                        labelInValue
                        mode="tags"
                        allowClear={true}
                        onSearch={(values) => setSearchTags(values.trim())}
                        options={contragentsTags?.data?.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                    />
                </Form.Item>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.comment)} name="comment"
                           data-test="comment">
                    {/*<Input.TextArea rows={4} disabled={loading || props.onlyView}/>*/}
                    {!props.onlyView && newContragent.id != '-1' && (
                        <ReactQuill
                            readOnly={loading || props.onlyView}
                            key={`${newContragent.id}`}
                            theme="snow"
                            value={comment}
                            onChange={setComment}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link'],
                                    ['clean'],
                                ],
                            }}
                        />
                    )}
                    {props.onlyView && comment && (
                        <span className={'dangerouslySetInnerHTML'} dangerouslySetInnerHTML={{ __html: comment }} />
                    )}
                </Form.Item>
                <Form.Item
                    label={t(localeKeys.contragentsListScreen.modal.fields.inn)}
                    name="inn"
                    rules={[{ required: true, message: t(localeKeys.shared.requiredField) }]}
                    data-test="inn"
                >
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.ogrn)} name="ogrn" data-test="ogrn">
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.address)} name="legal_address"
                           data-test="address">
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.postIndex)} name="postal_address"
                           data-test="address_postal">
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                    {/*//phone*/}
                    <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.phone)} name="phone"
                               data-test="phone">
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                    {/*//email*/}
                    <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.email)} name="email"
                               data-test="email">
                    <Input variant={props.onlyView ? 'borderless' : 'outlined'} disabled={loading || props.onlyView} />
                </Form.Item>
                    {/*//documents*/}
                    <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.documents)} name="documents">
                    {/*{JSON.stringify(propsUpload.fileList)}*/}
                        <Upload id={`${props.localItem.id}`} key={`${props.localItem.id}`} {...propsUpload}>
                        {!props.onlyView && (
                            <Button size={'small'} icon={<UploadOutlined />}>
                                {uploading ? t(localeKeys.shared.loading) : t(localeKeys.shared.loadBtn)}
                            </Button>
                        )}
                    </Upload>
                </Form.Item>
                    {/*//requisites*/}
                    <h4>{t(localeKeys.contragentsListScreen.modal.fields.requisites)}</h4>
                <Form.List name="requisites">
                    {(fields, { add, remove }) => (
                        <>
                            {fields
                                .filter((field) => form.getFieldValue('requisites')[field.name]?.remove !== true)
                                .map((field, index) => (
                                    <div
                                        key={field.key}
                                        className={'requisites_row'}
                                        data-test={`requisites_row_${field.key}`}
                                    >
                                        {/*{JSON.stringify(field)}*/}
                                        {/*{JSON.stringify(form.getFieldValue('requisites')[field.name])}*/}
                                        <Form.Item
                                            label={t(localeKeys.contragentsListScreen.modal.fields.bankName)}
                                            name={[field.name, 'bank_name']}
                                            data-test="requisites_name"
                                        >
                                            <Input
                                                variant={props.onlyView ? 'borderless' : 'outlined'}
                                                disabled={loading || props.onlyView}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t(localeKeys.contragentsListScreen.modal.fields.account)}
                                            name={[field.name, 'account']}
                                            data-test="requisites_account_number"
                                        >
                                            <Input
                                                variant={props.onlyView ? 'borderless' : 'outlined'}
                                                disabled={loading || props.onlyView}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.bik)}
                                                   name={[field.name, 'bik']} data-test="requisites_bik">
                                            <Input
                                                variant={props.onlyView ? 'borderless' : 'outlined'}
                                                disabled={loading || props.onlyView}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t(localeKeys.contragentsListScreen.modal.fields.ks)}
                                            name={[field.name, 'correspondent_account']}
                                            data-test="requisites_correspondent_account"
                                        >
                                            <Input
                                                variant={props.onlyView ? 'borderless' : 'outlined'}
                                                disabled={loading || props.onlyView}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.kpp)}
                                                   name={[field.name, 'kpp']} data-test="requisites_kpp">
                                            <Input
                                                variant={props.onlyView ? 'borderless' : 'outlined'}
                                                disabled={loading || props.onlyView}
                                            />
                                        </Form.Item>
                                        {!props.onlyView && (
                                            <Form.Item className={'actions'} wrapperCol={{ span: 24 }}>
                                                <Button
                                                    size={'small'}
                                                    data-test="requisites_remove"
                                                    onClick={() => {
                                                        // remove(field.name);
                                                        form.setFieldsValue({
                                                            requisites: form
                                                                .getFieldValue('requisites')
                                                                .map((item: any, index: any) => {
                                                                    if (index === field.name) {
                                                                        return { ...item, remove: true };
                                                                    }
                                                                    return item;
                                                                }),
                                                        });
                                                    }}
                                                >
                                                    Удалить
                                                </Button>
                                            </Form.Item>
                                        )}
                                    </div>
                                ))}

                            {!props.onlyView && (
                                <Form.Item className={'actions'} wrapperCol={{ span: 24 }}>
                                    <Button
                                        data-test="requisites_add"
                                        className={'requisites_add_btn'}
                                        onClick={() =>
                                            add({
                                                bank_name: '',
                                                account: '',
                                                bik: '',
                                                correspondent_account: '',
                                                kpp: '',
                                            })
                                        }
                                        size={'small'}
                                    >
                                        <PlusOutlined /> {t(localeKeys.contragentsListScreen.modal.fields.addRequisites)}
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.contactsContragent)} name={'contacts'}
                           data-test="contacts">
                    {!props.onlyView && newContragent.id != '-1' && (
                        <ReactQuill
                            readOnly={loading || props.onlyView}
                            key={`${newContragent.id}`}
                            theme="snow"
                            value={contacts}
                            onChange={setContact}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link'],
                                    ['clean'],
                                ],
                            }}
                        />
                    )}
                    {props.onlyView && contacts && (
                        <span className={'dangerouslySetInnerHTML'} dangerouslySetInnerHTML={{ __html: contacts }} />
                    )}
                </Form.Item>
                <Form.Item label={t(localeKeys.contragentsListScreen.modal.fields.contactsPnk)}
                           data-test="contacts_pnk">
                    {!props.onlyView && newContragent.id != '-1' && (
                        <ReactQuill
                            readOnly={loading || props.onlyView}
                            key={`${newContragent.id}`}
                            theme="snow"
                            value={relatedUser}
                            onChange={setRelatedUser}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link'],
                                    ['clean'],
                                ],
                            }}
                        />
                    )}
                    {props.onlyView && relatedUser && (
                        <span className={'dangerouslySetInnerHTML'} dangerouslySetInnerHTML={{ __html: relatedUser }} />
                    )}
                </Form.Item>
                </span>

                <span style={{ display: activeTab == 'other' ? 'block' : 'none' }} className={'NomenclaturesList'}>
                    <Button
                        type={'link'}
                        className={'btn-add-nomenclature'}
                        onClick={addNomenclature}
                    >
                        + Номенклатура
                    </Button>

                    <Table
                        scroll={{ y: window.outerHeight / 2 }}
                        pagination={false}
                        className={'nomenclatures-table'}
                        columns={contragentListModalColumns({
                            items: nomenclatures?.rows ?? [],
                            setExpandedRowKeys,
                            expandedRowKeys,
                            locations: locations?.data ?? [],
                            handleRemoveNomenclature: handleRemoveNomenclature,
                            updateLocationsByNomenclature,
                            stateNomenclatures,
                        })}
                        dataSource={nomenclaturesRows}
                        onRow={ ( record ) => ({
                            onClick: () => {
                                const id: string = record.nomenclature_id || record.type_id || record.section_id as string;
                                let fullId = contragentListModalId(record);

                                const isExpanded = expandedRowKeys.includes( fullId );

                                if ( record.row_type == 'section' ) {
                                    const workTypeIds = nomenclaturesRows
                                        .filter( row => row.section_id == id && row.row_type == 'work_type' )
                                        .map( row => `${row.section_id}_${row.type_id}` );

                                    setExpandedRowKeys( isExpanded ? [ ...expandedRowKeys.filter( ( key ) => key !== fullId && !workTypeIds.includes(key ) ) ] : [ ...expandedRowKeys, fullId ] );
                                }

                                if ( record.row_type == 'work_type' ) {
                                    setExpandedRowKeys( isExpanded ? [ ...expandedRowKeys.filter( ( key ) => key !== fullId ) ] : [ ...expandedRowKeys, fullId ] );
                                }
                            },
                        }) }
                    >

                    </Table>


                </span>
            </Form>
        </Modal>
    )
        ;
};

export default ContragentListModal;
