import React, {FunctionComponent, useEffect, useState} from 'react';
import {getWData} from '../../../table_extends/getWData';
import {Select} from 'antd/lib';
import {Button, Col, Form, Input, Modal, notification, Row, TreeSelect} from 'antd';
import {DefaultOptionType} from 'rc-select/lib/Select';
import moment from 'moment';
import {MyDatePicker} from '../../../../shared/stopEvent';
import {
    emptyIAddContracts,
    emptyIModalAddContract, getFullId, getFullIdContract,
    IAddContracts,
    IApiContragent,
    IFormAddContractsMany,
} from '../../../../Models/ContragentForm';
import ModalAddContractForm from './modal_add_contracts_form';
import {getEmptyTableRecord} from '../TableRecord';
import ModalAddContractUpload from './modal_add_contracts_upload';
import {api} from '../../../../shared/api_client';
import {getPassportCache} from '../../../table_extends/loadData';
import {getTreeRows3} from '../../../../shared/getTreeRows3';
import tokenActions, {AccessItem} from '../../../../actions/tokenActions';
import {DateFormat} from '../../../../shared/dateFormat';
import enUS from 'antd/es/calendar/locale/en_US';
import {addProjectDocumentDb, DocumentType, isOnline, updateProjectTableRowDb} from '../../../../indexedb';
import ModalWarning from './modal_warning';
import {sizes} from '../../../../shared/sizes';
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../../../i18n/localeKeys";
import {useContractsAgreement} from "../../../../actions/useContractsAgreement";


export interface IModalAddGlobal {
    isOpen: boolean
    open: boolean | string | undefined,
    onOk: (item: IFormAddContractsMany) => void | undefined,
    onCancel: () => void | undefined
}

export const filterOption = (input: string, option?: DefaultOptionType) => {
    return (option?.label ?? '')?.toString()?.toLowerCase().includes(input.toLowerCase());
};


function ModalAddContract({open, onOk, onCancel, isOpen}: IModalAddGlobal) {
    const rows3 = getWData().rows3;
    const [state, setState] = useState<IFormAddContractsMany>(emptyIModalAddContract());
    const [contractors, setContractors] = useState<DefaultOptionType[]>([]);
    const {t} = useTranslation();

    const editedContract: () => IFormAddContractsMany = () => {
        const contractLines = rows3.map(e => e.cns_contracts.map(e2 => ({...e2, line: e}))).flat().flat();

        const docId = contractLines.find(e => e.id == open)?.document_id;

        const contractLines2 = contractLines.filter(e => e.document_id == docId);

        console.log({contractLines2});

        if (contractLines2.length) {
            return {
                id: contractLines2[0].document_id,
                number: contractLines2[0]?.doc_number ?? '',
                date: contractLines2[0]?.doc_date ?? '',
                start_date: contractLines2[0]?.date_start ?? '',
                end_date: contractLines2[0]?.date_end ?? '',
                files: contractLines2[0].documents,
                contragent: contractLines2[0]?.contragent_id ?? '',
                contracts: contractLines2.map(line => ({
                    section_id: line.line.cns_section_id ?? '',
                    type_id: line.line.cns_group_id ?? '',
                    nomenclature_id: line.line.cns_nomenclature_id ?? '',
                    title: '',
                    date: '',
                    key: `line_${line.id}`,
                    size: Number(line.size),
                    price: Number(line.price),
                    sum_wat: Number(line.sum_wat),
                })),
            };
        }

        return emptyIModalAddContract();
    }

    const handleRemove = (id: string) => {
        setState({...state, contracts: state.contracts.filter(item => getFullIdContract(item) !== id)});
    };


    const handleSave = (row: IAddContracts) => {
        const index = state.contracts.findIndex(e => getFullIdContract(e) == getFullIdContract(row));
        console.log('handleSave', index, row);
        if (index > -1) {
            const contracts = state.contracts.map(item => {
                if (getFullIdContract(item) == getFullIdContract(row)) {
                    return {...item, ...row};
                }
                return item;
            });

            setState({...state, contracts: contracts});
        }
    };

    useEffect(() => {
        const listContractors: { [x: string]: DefaultOptionType } = {};
        const listContractorsCount: { [x: string]: number } = {};

        for (let item of state.contracts) {
            const record = rows3.find(e => getFullId(e) == getFullIdContract(item));

            for (let item2 of record?.cns_contractors ?? []) {
                listContractors[item2.value ?? ''] = {...item2};
                listContractorsCount[item2.value ?? ''] = (listContractorsCount[item2.value ?? ''] ?? 0) + 1;
            }
        }

        for (let [key, value] of Object.entries(listContractorsCount)) {
            if (value != state.contracts.length) {
                delete listContractors[key];
            }
        }

        const contractors = Object.values(listContractors);

        setContractors(contractors);

        if (contractors.length == 1) {
            setState({...state, contragent: (contractors[0].value as any)});
        }
    }, [state.contracts]);

    const isDisabled = !state.number || !state.date || !state.files.length || !state.contragent || !state.contracts.length || !state.start_date || !state.end_date;

    function resetState() {
        setState(emptyIModalAddContract());
        setContractors([]);
        setIsSending(false);
        setTree(getTreeRows3('contract'));
    }

    const [isSending, setIsSending] = useState(false);

    const [tree, setTree] = useState<DefaultOptionType[]>([]);

    useEffect(() => {
        setTree(getTreeRows3('contract'));
        if (typeof open == 'string') {
            const row = rows3.find(item => getFullId(item) == open);
            if (row) {
                onChangeTree([getFullId(row)]);
            } else {
                if (editedContract().number) {
                    setState({...editedContract()})
                }
            }
        }
    }, [isOpen]);

    function onChangeTree(options: string[]) {
        const contractsRes: IAddContracts[] = options.map(option => {
            const findItem = state.contracts.find(item => getFullIdContract(item) === option);

            if (!findItem) {
                const record = rows3.find(item => getFullId(item) === option);

                if (record) {
                    return {
                        ...emptyIAddContracts(),
                        nomenclature_id: record.cns_nomenclature_id ?? '',
                        type_id: record.cns_group_id ?? '',
                        section_id: record.cns_section_id ?? '',
                        title: record.cns_title ?? '',
                        size: Number(record.cns_budget_plan_size ?? ''),
                        price: Number(record.cns_budget_plan_price ?? ''),
                        sum_wat: Number(record.cns_budget_plan_size) * Number(record.cns_budget_plan_price),
                    };
                }
            }

            return findItem ?? emptyIAddContracts();
        });


        setState({...state, contracts: [...contractsRes], contragent: ''});
    }

    async function handleCreate() {
        setIsSending(true);
        const oldStateFiles = editedContract().files.map(e => e.id);

        const payload: IApiContragent = {
            contract_date: moment(state.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            start_date: moment(state.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            end_date: moment(state.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            contract_number: state.number,
            contractor_id: state.contragent,
            nomenclatures: state.contracts
                .map(e => {
                    const row = rows3.find(e2 => getFullId(e2) == getFullIdContract(e)) ?? getEmptyTableRecord();
                    return ({
                        project_id: getPassportCache().id ?? '',
                        section_id: row.cns_section_id ?? '',
                        type_id: row.cns_group_id ?? '',
                        nomenclature_id: row.cns_nomenclature_id ?? '',
                        price: Number(e.price),
                        volume: Number(e.size),
                        amount: Number(e.sum_wat),
                    });

                }),
            file_ids: state.id ? state.files.filter(e => !oldStateFiles.includes(e.id)).map(e => e.id) : state.files.map(e => e.id),
        };

        if (isOnline) {
            try {
                const res = (state.id) ? await api.documents.contractsUpdate(state.id, payload) : await api.documents.contractsCreate(payload);

                // await getWData().loadDataPassport();

                notification.success({message: t(localeKeys.table.add.contract.created)});

                resetState();
                onOk(state);

            } catch (e) {
                notification.error({message: (e as any)?.error?.message ?? t(localeKeys.table.add.contract.createError)});
            }
        } else {
            await addProjectDocumentDb(Math.random().toString(), DocumentType.contract, {
                ...payload,
                contractor: `${contractors.find(e => e.value == state.contragent)?.label}`,
                files: state.files,
            });

            for (let item of state.contracts) {
                const row = rows3.find(e => getFullId(e) == getFullIdContract(item)) ?? getEmptyTableRecord();

                row.cns_contracts.push({
                    id: Math.random().toString(),
                    project_contract_data_id: Math.random().toString(),
                    document_id: Math.random().toString(),

                    parent_id: row.cns_nomenclature_id ?? '',

                    parent_contract_id: null,

                    contragent: `${contractors.find(e => e.value == state.contragent)?.label}`,
                    contragent_id: state.contragent,

                    status: '',

                    size: `${item.size}`,
                    price: `${item.price}`,
                    sum_wat: `${item.sum_wat}`,

                    date_start: state.start_date,
                    date_end: state.end_date,

                    doc_number: state.number,
                    doc_date: state.date,

                    files: state.files.length,

                    documents: state.files.map(file => ({
                        id: file.id,
                        name: file.name,
                        url: file.id,
                        path: file.id,
                    })),
                });

                row.cns_budget_fakt_size = `${row.cns_contracts.reduce((sum, contract) => sum + Number(contract.size), 0)}`;
                row.cns_budget_fakt_price = `${row.cns_contracts.reduce((sum, contract) => sum + Number(contract.price), 0) / row.cns_contracts.length}`;
                row.cns_budget_fakt_sum_wat = `${row.cns_contracts.reduce((sum, contract) => sum + Number(contract.sum_wat), 0)}`;

                await updateProjectTableRowDb(getPassportCache().id ?? '', row);
            }


            notification.info({message: t(localeKeys.table.add.contract.saveWarning)});

            getWData().setRefresh(Math.random());
            resetState();
            onOk(state);
        }


        setIsSending(false);
    }

    const hasQuotaExceeded = state.contracts.filter(e => {
        if (state.id) return false;

        const record = rows3.find(e2 => getFullId(e2) == getFullIdContract(e)) ?? getEmptyTableRecord();
        const contracts = record.cns_contracts ?? [];
        const contractsSize = contracts.reduce((sum, contract) => sum + Number(contract.size), 0);
        const contractsPrice = contracts.reduce((sum, contract) => sum + Number(contract.price), 0) / contracts.length;
        const contractsSumWat = contracts.reduce((sum, contract) => sum + Number(contract.sum_wat), 0);

        if (e.size + contractsSize > Number.parseFloat(record.cns_budget_plan_size)) return true;
        if (((e.price + contractsPrice) / 2) > Number.parseFloat(record.cns_budget_plan_price)) return true;
        if (e.sum_wat + contractsSumWat > Number.parseFloat(record.cns_budget_plan_sum_wat)) return true;

        return false;
    }).length > 0;

    const isMobile = window.outerWidth < sizes.mobile;

    (window as any).modalAddContract = {
        props: {open, onOk, onCancel, isOpen},
        state,
        contractors,
        hasQuotaExceeded,
        isMobile,
        isOpen,
        open,
        tree,
    };

    const btnCreate = <Button type={'primary'} onClick={hasQuotaExceeded ? undefined : handleCreate}
                              disabled={isDisabled}
                              loading={isSending}
                              data-test={'BUTTON_ADD_CONTRACT'}
    >
        {t(localeKeys.table.add.contract.save)}
    </Button>;


    return (
        <>
            <Modal title={state.id ? t(localeKeys.table.add.contract.titleUpd) : t(localeKeys.table.add.contract.title)}
                   open={isOpen}
                   destroyOnClose={true}
                   closable={true}
                   onClose={() => {
                       resetState();
                       onCancel();
                   }}
                   onCancel={() => {
                       resetState();
                       onCancel();
                   }}
                   className="modal-add-contract"
                   footer={<>
                       <Button
                           type={'link'}
                           onClick={() => {
                               resetState();
                               onCancel();
                           }}> {t(localeKeys.shared.cancelBtn)} </Button>
                       {hasQuotaExceeded && !isDisabled ?
                           <ModalWarning
                               btn={btnCreate}
                               text={t(localeKeys.table.add.contract.quotaExceeded)}
                               onOk={handleCreate}
                               onCancel={() => {
                               }}
                           />
                           : btnCreate}

                   </>}
            >
                <Form layout={isMobile ? 'vertical' : 'horizontal'}>
                    <Row gutter={isMobile ? {sm: 10} : undefined}>
                        <Col span={isMobile ? 24 : 14}>
                            <Form.Item
                                label={t(localeKeys.table.add.contract.number)}
                                labelCol={{span: 10}}
                            >
                                <Input
                                    data-test={'INPUT_CONTRACT_NUMBER'}
                                    value={state.number}
                                    placeholder={''}
                                    onInput={val => {
                                        setState({...state, number: (val.target as any).value});
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isMobile ? 24 : 10}>
                            <Form.Item label={t(localeKeys.table.add.contract.date)} labelCol={{span: 6}}>
                                <MyDatePicker
                                    data-test={'INPUT_CONTRACT_DATE'}
                                    locale={enUS}
                                    style={{maxWidth: isMobile ? '100%' : '137'}}
                                    format={DateFormat}
                                    defaultValue={moment(state.date, 'YYYY-MM-DD')}
                                    onChange={(dates, dateStrings) => {
                                        setState({
                                            ...state,
                                            date: dates?.format('YYYY-MM-DD') ?? '',
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={t(localeKeys.table.add.contract.nomenclatures)} labelCol={{span: 6}}>
                                <TreeSelect
                                    data-test={'INPUT_CONTRACT_NOMENCLATURES'}
                                    placeholder=""
                                    treeCheckable={true}
                                    showSearch
                                    value={state.contracts.map(e => getFullIdContract(e)) as any}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    allowClear
                                    treeDefaultExpandAll={getWData().rows3.length < 100}
                                    onChange={onChangeTree}
                                    treeData={tree}
                                    filterTreeNode={(input, treeNode) => !!treeNode.label?.toString().toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            {/*{JSON.stringify(contractors)}*/}
                            <Form.Item label={t(localeKeys.table.add.contract.contractor)} labelCol={{span: 6}}>
                                <Select
                                    className={'input_contract_contragent'}
                                    data-test={'INPUT_CONTRACT_CONTRAGENT'}
                                    showSearch
                                    placeholder=""
                                    filterOption={filterOption}
                                    value={state.contragent}
                                    options={contractors.map((item) => {
                                        return ({
                                            value: item.value ?? '',
                                            label: item.label ?? '',
                                        });
                                    })}
                                    onChange={val => {
                                        setState({...state, contragent: val});
                                    }}
                                >
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={isMobile ? 11 : 12}>
                            <Form.Item label={t(localeKeys.table.add.contract.startDate)} labelCol={{span: 12}}>
                                <MyDatePicker
                                    popupClassName={'input_contract_start_date'}
                                    data-test={'INPUT_CONTRACT_START_DATE'}
                                    locale={enUS}
                                    style={{maxWidth: '100%'}}
                                    format={DateFormat}
                                    defaultValue={moment(state.start_date, 'YYYY-MM-DD')}
                                    onChange={(dates, dateStrings) => {
                                        setState({
                                            ...state,
                                            start_date: dates?.format('YYYY-MM-DD') ?? '',
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isMobile ? 11 : 12} offset={isMobile ? 2 : 0}>
                            <Form.Item label={t(localeKeys.table.add.contract.endDate)} labelCol={{span: 12}}>
                                <MyDatePicker
                                    popupClassName={'input_contract_end_date'}
                                    data-test={'INPUT_CONTRACT_END_DATE'}
                                    locale={enUS}
                                    style={{maxWidth: '100%'}}
                                    format={DateFormat}
                                    defaultValue={moment(state.end_date, 'YYYY-MM-DD')}
                                    onChange={(dates, dateStrings) => {
                                        setState({
                                            ...state,
                                            end_date: dates?.format('YYYY-MM-DD') ?? '',
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                {state.contracts.length != 0 && <div className={'modal-add-contract__list'}>
                    {state.contracts.map(e => <ModalAddContractForm
                        key={`ModalAddContractForm_${getFullIdContract(e)}`}
                        contractId={state.id}
                        item={e}
                        record={rows3.find(e2 => getFullId(e2) == getFullIdContract(e)) ?? getEmptyTableRecord()}
                        onDone={(item) => handleSave(item)}
                        onRemove={() => handleRemove(getFullIdContract(e))}
                    />)}
                </div>}


                <Row>
                    <Col span={isMobile ? 24 : 18} offset={isMobile ? 0 : 6}>
                        <ModalAddContractUpload
                            width={'170px'}
                            files={state.files}
                            onUploaded={fileUploaded => {
                                setState({...state, files: fileUploaded});
                            }}
                            disabled={tokenActions.contracts != AccessItem.EDIT && tokenActions.contracts != AccessItem.FULL}
                        />
                    </Col>
                </Row>

            </Modal>

        </>
    );
}

export default ModalAddContract;


export const ModalAddContractBind: FunctionComponent<{ isOpen: boolean | string }> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean | string>(false);
    const {clearCacheContractsAgreement} = useContractsAgreement()
    const handleClose = () => {
        setIsModalOpen(false);
        getWData().globalActions.isModalAddManyContracts = false;
        clearCacheContractsAgreement();
    };

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen(props.isOpen);
        }
    }, [props.isOpen]);

    return <ModalAddContract isOpen={!!isModalOpen} open={isModalOpen} onOk={handleClose} onCancel={handleClose}/>;

};
