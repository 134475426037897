import { FunctionComponent } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { getEmptyContractorShort } from '../../../../Models/IContragent';

const ModalSelectContragents: FunctionComponent<{isOpen: boolean}> = (props) => {
    const canvasItem = document.querySelector('canvas')?.getBoundingClientRect();
    const cell = getWData().cellOpen?.cell;
    const modalW = document.querySelector('.ModalSelectContragents')?.clientWidth;
    const spaceRight = window.innerWidth - ((getWData().cellOpen?.cell?.right ?? 0) - getWData().grid?.scrollLeft) > (modalW ?? 200);
    let y = cell?.top + cell?.height - window.scrollY - getWData().grid?.scrollTop;
    let x = cell?.left - getWData().grid?.scrollLeft;

    if (!spaceRight) {
        x = x - (modalW ? modalW + 40 : 200);
    }

    const contractors = getWData().cellOpen?.record?.cns_contractors ?? [];


    if (!props.isOpen) return null;

    return (
        <div className={'ModalSelectContragents'} style={{
            top: `${y + (canvasItem?.top ?? 0)}px`,
            left: `${x + (canvasItem?.left ?? 0)}px`,
        }}>
            {contractors.length == 0 && <>Нет контрагентов</>}

            {contractors.map( item => {
                const contractorDb = getWData().db.dbContractors.find(e => e.id == item.value) ?? getEmptyContractorShort();
                const color = contractorDb.rating_label === 'Желтый' ? '#fff7af' : contractorDb.rating_label === 'Зеленый' ? '#bbffce' : contractorDb.rating_label === 'Черный' ? 'black' : contractorDb.rating_label === 'Без рейтинга' ? undefined : undefined;
                let textColor = '';
                if (color == 'black') {
                    textColor = 'white';
                }

                return <span
                    style={{ backgroundColor: color, color: textColor }}
                    onClick={() => {
                        getWData().globalActions.isModalContragentView = item.value;
                        getWData().setRefresh(Math.random());
                    }}
                >
                    {item.label}
                </span>;
            })}

        </div>
    );
}


export default ModalSelectContragents;


export function closeModalSelectContragents() {
    if (!getWData().cellOpen?.isModalSelectContractors) return;
    console.log('closeModalSelectContragents');

    if (getWData().cellOpen?.isModalSelectContractors) {
        getWData().cellOpen!.isModalSelectContractors = false;
    }
    getWData().setRefresh(Math.random());
}
