import {TableRecord} from "../canvas_table/components/TableRecord";
import {ProjectError} from "../../ApiEpo";


export function getErrorsTableView(errors: ProjectError[], rows: TableRecord[], rows2: TableRecord[], rows3: TableRecord[], rows4: TableRecord[], rows5: TableRecord[]) {
  const res: {[x: string]: {[y: string]: string}} = {};

  const errorsSort:ProjectError[] = [...errors];
  // closed_at в Firefox. В хроме сортировка по undefined значениям может обрабатываться иначе. Добавил проверку
  // errorsSort.sort((e1, e2) => {
  //   if (e1.closed_at) {
  //     return -1;
  //   }
  //   return 0;
  // })
  errorsSort.sort((e1, e2) => {
    if (e1.closed_at && !e2.closed_at) return -1;
    if (!e1.closed_at && e2.closed_at) return 1;
    return 0;
  });

  for (const e of errorsSort) {
    const fields = e.error?.fields ?? [];
    const rowId = e.row_number ?? '';
    let docId = '';
    let entityId = '';

    if (e.error?.contract?.id) {
      const contractId = e.error?.contract?.id;
      const record = rows4.find(e => e.cns_contracts.find(e2 => e2.id == contractId));
      entityId = record?.cns_number ?? '';
      docId = record?.cns_number ?? '';
    }

    if (e.error?.payment_invoice?.id) {
      const errorId = e.error?.payment_invoice?.id ?? '';
      entityId = rows5.find(e => e.cns_invoices.find(e2 => e2.id == errorId))?.cns_number ?? "";
      const invoice = rows3
        .map(row => row.cns_invoices ?? [])
        .find( invoices => invoices.find(e2 => e2.id == errorId) )
        ?.[0];
      const contractRow = rows4.find(row => row.cns_invoices.find(e => e.id == invoice?.id))
      docId = contractRow?.cns_number ?? '';
    }

    if (e.error?.waybill?.id) {
      const errorId = e.error?.waybill?.id ?? '';
      entityId = rows5.find(e => e.cns_way_bills.find(e2 => e2.id == errorId))?.cns_number ?? "";
      const waybill = rows3
        .map(row => row.cns_way_bills ?? [])
        .find( waybills => waybills.find(e2 => e2.id == errorId) )
        ?.[0];
      const contractRow = rows4.find(row => row.cns_way_bills.find(e => e.id == waybill?.id))
      docId = contractRow?.cns_number ?? '';
    }

    if (e.error?.bill?.id) {
      const errorId = e.error?.bill?.id ?? '';
      entityId = rows5.find(e => e.cns_bills.find(e2 => e2.id == errorId))?.cns_number ?? "";
      const bill = rows3
        .map(row => row.cns_bills ?? [])
        .find( bills => bills.find(e2 => e2.id == errorId) )
        ?.[0];
      const contractRow = rows4.find(row => row.cns_bills.find(e => e.id == bill?.id))
      docId = contractRow?.cns_number ?? '';
    }

    // if (e.nomenclature_id == '4d05649c-e04a-4f38-89e7-573216d963cb') {
    //   console.log(res[rowId])
    // }
    //
    if (!res[rowId]) {
      res[rowId] = { 'type': 'nomenclature' };
    }
    if (!res[docId]) {
      if (e?.type?.includes('contract')) {
        res[docId] = { 'type': 'contract' };
      } else if (e?.type?.includes('invoice')) {
        res[docId] = { 'type': 'invoice' };
      } else if (e?.type?.includes('waybill')) {
        res[docId] = { 'type': 'waybill' };
      } else if (e?.type?.includes('bill')) {
        res[docId] = { 'type': 'bill' };
      }
    }

    if (!res[entityId]) {
      if (e?.type?.includes('contract')) {
        res[entityId] = { 'type': 'contract' };
      } else if (e?.type?.includes('invoice')) {
        res[entityId] = { 'type': 'invoice' };
      } else if (e?.type?.includes('waybill')) {
        res[entityId] = { 'type': 'waybill' };
      } else if (e?.type?.includes('bill')) {
        res[entityId] = { 'type': 'bill' };
      }
    }

    for (const field of fields) {
      let nameField: keyof TableRecord = 'cns_id';

      switch (field) {
        case "contract_data-work_start_date":
          nameField = "cns_contact_date_start"
          break;
        case "contract_data-work_end_date":
          nameField = "cns_contact_date_end"
          break;
        case "contract_data-planning_date":
          nameField = "cns_plane_date"
          break;
        case "bills-volume":
          nameField = "cns_bill_size"
          break;
        case "bills-price":
          nameField = "cns_bill_price"
          break;
        case "bills-amount":
          nameField = "cns_bill_sum"
          break;
        case "delivered_completed-volume":
          nameField = "cns_well_bill_size"
          break;
        case "delivered_completed-amount":
          nameField = "cns_well_bill_delivery_sum"
          break;
        case "payment_invoices-price":
          nameField = "cns_invoice_price"
          break;
        case "payment_invoices-volume":
          nameField = "cns_invoice_size"
          break;
        case "payment_invoices-amount":
          nameField = "cns_invoice_sum"
          break;
        case "fact_budget-volume":
          nameField = "cns_budget_fakt_size"
          break;
        case "fact_budget-price":
          nameField = "cns_budget_fakt_price";
          break;
        case "fact_budget-amount":
          nameField = "cns_budget_fakt_sum_wat";
          break;
      }

      res[rowId][nameField] = (e as any).status;
      res[docId][nameField] = (e as any).status;
      res[entityId][nameField] = (e as any).status;
    }
  }

  delete res[''];

  for (const rowIndex in rows2) {
    const row = rows2[rowIndex]
    const row3ByGroup = rows3.filter(e => e.cns_group_id == row.cns_group_id)

    if (!res[row.cns_number]) {
      res[row.cns_number] = {'type': 'group'}
    }

    const hasErrors2: {[y: string]: string} = {}

    for (const rowIndexNom in row3ByGroup) {
      const rowNom = row3ByGroup[rowIndexNom]

      const hasErrors = res[rowNom.cns_number ?? '']

      for (let key in hasErrors) {
        if (hasErrors[key] != 'closed') {
          hasErrors2[key] = hasErrors[key]
        }
      }
    }

    for (const rowIndexNom in row3ByGroup) {
      const rowNom = row3ByGroup[rowIndexNom]

      const hasErrors = res[rowNom.cns_number ?? '']

      for (let key in hasErrors) {
        if (hasErrors[key] == 'closed' && !hasErrors2[key]) {
          hasErrors2[key] = hasErrors[key]
        }
      }
    }

    res[row.cns_number] = { ...hasErrors2, ...res[row.cns_number],};
  }

  for (const rowIndex in rows) {
    const row = rows[rowIndex]
    const row3BySection = rows3.filter(e => e.cns_section_id == row.cns_section_id)

    if (!res[row.cns_number]) {
      res[row.cns_number] = { 'type': 'section' }
    }

    const hasErrors2: {[y: string]: string} = {}

    for (const rowIndexNom in row3BySection) {
      const rowNom = row3BySection[rowIndexNom]

      const hasErrors = res[rowNom.cns_number ?? '']

      for (let key in hasErrors) {
        if (hasErrors[key] != 'closed') {
          hasErrors2[key] = hasErrors[key]
        }
      }
    }

    for (const rowIndexNom in row3BySection) {
      const rowNom = row3BySection[rowIndexNom]

      const hasErrors = res[rowNom.cns_number ?? '']

      for (let key in hasErrors) {
        if (hasErrors[key] == 'closed' && !hasErrors2[key]) {
          hasErrors2[key] = hasErrors[key]
        }
      }
    }

    res[row.cns_number] = { ...hasErrors2, ...res[row.cns_number],};
  }

  return res;
}
