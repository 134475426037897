import { getWData } from '../../../table_extends/getWData';
import { Button, Input, notification, Tree, TreeProps } from 'antd';
import { uniq } from 'lodash';
import { useRef, useState } from 'react';
import { doConnectNomenclatures, doConnectSections, doConnectWorkTypes } from '../../actions/doConnectSection';
import { contragentListModalId } from '../../../contragents/ContragentListModalColumns';

function ModalConnectNomenclaturesContent(props: {
    section_id?: string,
    group_id?: string,
    closeModal: () => void,
    defaultSelect?: string[],
    onAction?: (selected: string[]) => Promise<void>
}) {
    let selected: string[] = [];

    type TreeNode = {
        key: string,
        title: string,
        disabled: boolean,
        children: {
            key: string,
            title: string,
            disabled: boolean,
            children: { key: string, title: string, disabled: boolean }[]
        }[]
    };

    const tree: TreeNode[] = [];

    let sections = getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'section');

    for (const section of sections) {
        const workTypesBySection = getWData().db.nomenclaturesStruct.filter(e => e.section_id == section.section_id && e.row_type == 'work_type');

        const hasNomenclaturesBySection = getWData().rows3.filter(nomenclature => nomenclature.cns_section_id == section.section_id).length;
        const countNomenclaturesBySection = getWData().db.nomenclaturesStruct.filter(e => e.section_id == section.section_id && e.row_type == 'nomenclature').length;

        let id = section.section_id ?? '';

        let disabled = countNomenclaturesBySection > 0 ? countNomenclaturesBySection == hasNomenclaturesBySection : !!getWData().rows.find(section2 => section2.cns_section_id == section.section_id);

        if (props.defaultSelect?.length) {
            disabled = false;
        }

        if (props.defaultSelect?.includes(id)) {
            const nomenclaturesBySection = getWData().db.nomenclaturesStruct.filter(e => e.section_id == section.section_id && e.row_type == 'nomenclature');
            const hasSelectedNomenclatures = nomenclaturesBySection.filter(e => props.defaultSelect?.includes(`${e.section_id}_${e.type_id}_${e.nomenclature_id}`)).length;

            if (hasSelectedNomenclatures == nomenclaturesBySection.length) {
                disabled = true;
            }
        }

        tree.push({
            key: id,
            title: section.title ?? '',
            disabled: disabled,
            children: workTypesBySection.map(group => {
                const nomenclaturesByGroup = getWData().db.nomenclaturesStruct.filter(e => e.type_id == group.type_id && e.section_id == section.section_id && e.row_type == 'nomenclature');

                const hasNomenclaturesByGroup = getWData().rows3.filter(nomenclature => nomenclature.cns_group_id == group.type_id && nomenclature.cns_section_id == section.section_id).length;
                const countNomenclaturesByGroup = nomenclaturesByGroup.length;

                const addedGroup = getWData().rows2.find(e => e.cns_group_id == group.type_id && e.cns_section_id == section.section_id);

                const id = `${section.section_id}_${group.type_id}`;

                let disabled = countNomenclaturesByGroup > 0 ? countNomenclaturesByGroup == hasNomenclaturesByGroup : !!addedGroup;

                if (props.defaultSelect?.length) {
                    disabled = false;
                }

                if (props.defaultSelect?.includes(id)) {
                    const nomenclaturesByGroup = getWData().db.nomenclaturesStruct.filter(e => e.type_id == group.type_id && e.section_id == section.section_id && e.row_type == 'nomenclature');
                    const hasSelectedNomenclatures = nomenclaturesByGroup.filter(e => props.defaultSelect?.includes(`${e.section_id}_${e.type_id}_${e.nomenclature_id}`)).length;

                    if (hasSelectedNomenclatures == nomenclaturesByGroup.length) {
                        disabled = true;
                    }
                }

                return ({
                    key: id,
                    title: group.title ?? '',
                    disabled: disabled,
                    children: nomenclaturesByGroup.map(item => {
                        const id = `${item.section_id}_${item.type_id}_${item.nomenclature_id}`;

                        let disabled = getWData().rows3.map(e => e.cns_id).includes(contragentListModalId(item));

                        if (props.defaultSelect?.length) {
                            disabled = false;
                        }

                        if (props.defaultSelect?.includes(id)) {
                            disabled = true;
                        }

                        return ({
                            key: id,
                            title: item.title ?? '',
                            disabled: disabled,
                        });
                    }),
                });
            }),
        });
    }

    const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
        const ids = ((checkedKeys as any) as string[]);
        const nomenclatures = getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'nomenclature' && ids.includes(contragentListModalId(e)));

        const sectionIds = uniq(nomenclatures.map(e => e.section_id ?? ''));
        const groupIds = uniq(nomenclatures.map(e => `${e.section_id}_${e.type_id}` ?? ''));
        const nomenclatureIds = uniq(nomenclatures.map(e => `${e.section_id}_${e.type_id}_${e.nomenclature_id}` ?? ''));

        selected = uniq([...ids, ...nomenclatureIds, ...sectionIds, ...groupIds]);
    };

    const [search, setSearch] = useState<string>('');

    const [treeSearch, setTreeSearch] = useState<TreeNode[]>(tree);

    const treeRef = useRef<any>(null);
    const [processSave, setProcessSave] = useState<boolean>(false);

    const clearingDefaultSelect = props.defaultSelect?.filter(id => {
        let flag = false;

        for (const row of getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'section')) {
            if (id == contragentListModalId(row)) {
                const nomenclaturesBySection = getWData().db.nomenclaturesStruct.filter(e => e.section_id == row.section_id && e.row_type == 'nomenclature');
                const countSelected = nomenclaturesBySection.filter(item => props.defaultSelect?.includes(contragentListModalId(item))).length;

                if (countSelected == nomenclaturesBySection.length) {
                    flag = true;
                }
            }
        }

        for (const row of getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'work_type')) {
            if (id == contragentListModalId(row)) {
                const nomenclaturesByGroup = getWData().db.nomenclaturesStruct.filter(e => e.type_id == row.type_id && e.section_id == row.section_id && e.row_type == 'nomenclature');
                const countSelected = nomenclaturesByGroup.filter(item => props.defaultSelect?.includes(contragentListModalId(item))).length;

                if (countSelected == nomenclaturesByGroup.length) {
                    flag = true;
                }
            }
        }

        for (const row of getWData().db.nomenclaturesStruct.filter(e => e.row_type == 'nomenclature')) {
            if (id == contragentListModalId(row)) {
                flag = true;
            }
        }

        return flag;
    }) ?? [];

    (window as any).connectNomenclatureModal = {
        tree,
        treeSearch,
        processSave,
        search,
        sections,
    };

    return <>
        <div className={`ModalConnectNomenclature`}>
            <Input
                disabled={processSave}
                onInput={(e: any) => {
                    let tree2: TreeNode[] = JSON.parse(JSON.stringify(tree));
                    const search_text = e.target.value.trim().toLowerCase();

                    for (let sectionKey = tree2.length - 1; sectionKey >= 0; sectionKey--) {
                        const section = tree2[sectionKey];
                        let flagHasInSection = false;

                        for (let groupKey = section.children.length - 1; groupKey >= 0; groupKey--) {
                            const group = section.children[groupKey];
                            let flagHasInGroup = false;

                            for (let nomenclatureKey = group.children.length - 1; nomenclatureKey >= 0; nomenclatureKey--) {
                                const nomenclature = group.children[nomenclatureKey];
                                if (!nomenclature.title.toLowerCase().includes(search_text)) {
                                    group.children.splice(nomenclatureKey, 1);
                                } else {
                                    flagHasInGroup = true;
                                }
                            }

                            if (!group.title.toLowerCase().includes(search_text) && !flagHasInGroup) {
                                section.children.splice(groupKey, 1);
                            } else {
                                flagHasInSection = true;
                            }
                        }

                        if (!section.title.toLowerCase().includes(search_text) && !flagHasInSection) {
                            tree2.splice(sectionKey, 1);
                        }
                    }

                    setTreeSearch(tree2);
                    setSearch(e.target.value);
                }}
                placeholder={'Поиск'}
            />
            <br />
            <br />

            <Tree
                disabled={processSave}
                ref={treeRef}
                checkable
                treeData={treeSearch}
                defaultExpandedKeys={[props.section_id ?? '', props.group_id ?? '']}
                defaultCheckedKeys={clearingDefaultSelect ?? []}
                onCheck={onCheck}
            />
        </div>
        <div style={{ textAlign: 'right', paddingTop: '20px' }}>
            <Button
                disabled={processSave}
                size={'small'}
                type={'text'}
                onClick={async () => {
                    props.closeModal();
                }}
            >
                Отмена
            </Button> &nbsp; &nbsp;
            <Button
                data-test={'BTN_CONNECT_NOMENCLATURES'}
                disabled={processSave}
                loading={processSave}
                size={'small'}
                type={'primary'}
                onClick={async () => {
                    setProcessSave(true);
                    if (props.onAction) {
                        await props.onAction(selected);
                    } else {
                        try {

                            const resultSections = selected.filter(uid => getWData().db.nomenclaturesStruct.find(item => uid === contragentListModalId(item) && item.row_type === 'section'));
                            const resultGroups = selected.filter(uid => getWData().db.nomenclaturesStruct.find(item => contragentListModalId(item) === uid && item.row_type === 'work_type'));
                            const resultNomenclatures = selected.filter(uid => getWData().db.nomenclaturesStruct.find(item => contragentListModalId(item) === uid && item.row_type === 'nomenclature'));

                            console.log({ resultSections, resultGroups, resultNomenclatures });

                            await doConnectSections(resultSections.filter(section_id => !getWData().rows.map(e => e.cns_section_id).includes(section_id)));

                            for (const section of resultSections) {
                                await doConnectWorkTypes(section, resultGroups
                                    .filter(uid => uid.split('_')[0] === section)
                                    .map(e => e.split('_')[1])
                                    .filter(group_id => !getWData().rows2.map(e => e.cns_group_id).includes(group_id)),
                                );
                            }

                            for (const section of resultSections) {
                                const section_id = section;
                                for (const group of resultGroups) {
                                    const group_id = group.split('_')[1];

                                    if (section_id == group.split('_')[0]) {
                                        const nomenclatures = resultNomenclatures
                                            .filter(uid => {
                                                const [section_id2, type_id2, nomenclature_id2] = uid.split('_');
                                                return section_id2 === section_id && type_id2 === group_id;
                                            })
                                            .map(e => e.split('_')[2]);

                                        await doConnectNomenclatures(section_id, group_id, nomenclatures);
                                    }
                                }
                            }
                            props.closeModal();

                        } catch (e) {
                            console.error(e);

                            notification.error({
                                message: 'Не удалось добавить номенклатуры',
                            });
                        }

                    }
                    setProcessSave(false);

                }}
            >
                Добавить
            </Button>
        </div>
    </>;
}


export default ModalConnectNomenclaturesContent;
