import { getWData } from '../screens/table_extends/getWData';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { BaseOptionType } from 'antd/es/select/index';
import {RecordStatus} from "../screens/canvas_table/components/TableRecord";
import { getFullId } from "../Models/ContragentForm";

export function getTreeRows3(type: 'contract' | 'invoice' | 'waybill' | 'bill') {
  const res: DefaultOptionType[] = [];

  const errors = getWData().errors ?? [];

  const errors1 = errors.filter(e => {
    if (e.closed_at) return;

    if (type == 'contract') {
      return e.type?.includes('contract') && e.error?.level == 'error';
    } else if (type == 'invoice') {
      return e.type?.includes('invoice');
    } else if (type == 'waybill') {
      return e.type?.includes('waybill');
    } else if (type == 'bill') {
      return e.type?.includes('bill');
    }

    return false;
  });


  for (let item of getWData().rows) {
    const hasGroups = !!getWData().rows3.filter( e => e.cns_section_id == item.cns_section_id && e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED).length;

    const noms = getWData().rows3.filter(e => e.cns_section_id == item.cns_section_id);
    const nomsWithErrors = noms.filter(e => errors1.find(e2 => e2.nomenclature_id == e.cns_nomenclature_id));

    let itemRes: DefaultOptionType = {
      key: item.cns_section_id ?? '',
      value: getFullId(item),
      label: `${item.cns_number} ${item.cns_title ?? '-'}`,
      disableCheckbox: !hasGroups || noms.length == nomsWithErrors.length,
    };

    const children: BaseOptionType[] = [];
    for (let item2 of getWData().rows2) {

      if (item2.cns_section_id == item.cns_section_id) {
        const hasNoms = !!getWData().rows3.filter( e => e.cns_group_id == item2.cns_group_id && e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED).length;
        const noms = getWData().rows3.filter(e => e.cns_group_id == item2.cns_group_id);
        const nomsWithErrors = noms.filter(e => errors1.find(e2 => e2.nomenclature_id == e.cns_nomenclature_id));

        const itemRes2: DefaultOptionType = {
          key: item2.cns_group_id ?? '',
          value: getFullId(item2),
          label: `${item2.cns_number} ${item2.cns_title ?? '-'}`,
          children: getWData().rows3
            .filter(e => e.cns_section_id == item.cns_section_id && e.cns_group_id == item2.cns_group_id)
            .map(e => {
              const hasErrors = !!errors1.find(e2 =>  e2.nomenclature_id == e.cns_nomenclature_id)

              return ({
                key: e.cns_nomenclature_id ?? '',
                value: getFullId(e),
                label: `${e.cns_number} ${e.cns_title ?? '-'}`,
                disabled: e.cns_status != RecordStatus.BUDGET_PLAN_APPROVED || hasErrors,
              });
            }),
          disableCheckbox: !hasNoms || noms.length == nomsWithErrors.length,
        };

        children.push(itemRes2);
      }
    }

    itemRes = {
      ...itemRes,
      children: children,
    };

    res.push(itemRes);
  }

  return res;
}
