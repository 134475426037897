import { Button, Checkbox, Input } from 'antd';
import { FunctionComponent } from 'react';

const CustomTableSearch: FunctionComponent<{
    items: {label: string, value: string}[];
    filterSearch: { value: string; setValue: (text: string) => void };
    filterState: { value: string[]; setValue: (val: string[]) => void };
    setSelectedKeys: (val: any) => void;
    confirm: (val: any) => void;
}> = (props) => {
    return (
        <div className={'custom_search'}>
            <Input
                placeholder={'Найти'}
                value={props.filterSearch.value}
                onChange={(e) => props.filterSearch.setValue(e.target.value)}
            />
            {/*{JSON.stringify(props.items)}*/}
            {/*{JSON.stringify(props.filterState.value)}*/}
            <Checkbox.Group
                options={props.items}
                value={props.filterState.value}
                onChange={(val) => {
                    props.filterState.setValue(val as any);
                }}
            />
            {!props.items.length && <div> Нет данных </div>}
            <div className={'wrap_btns'}>
                <Button
                    disabled={!props.filterState.value.length}
                    size={'small'}
                    type={'link'}
                    onClick={(val) => {
                        props.filterSearch.setValue('');
                        props.filterState.setValue([]);
                        props.setSelectedKeys([]);
                        props.confirm({ closeDropdown: true });
                    }}
                >
                    Сбросить
                </Button>
                <Button
                    size={'small'}
                    type={'primary'}
                    onClick={(val) => {
                        props.setSelectedKeys(props.filterState.value as any);
                        props.confirm({ closeDropdown: true });
                    }}
                >
                    Применить
                </Button>
            </div>
        </div>
    );
};

export default CustomTableSearch;
